import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { SearchBar } from "../../components/SearchBar/SearchBarTemplate";
import { TALKGROUP } from "../../types/talkgroup";
import {
  deleteYoberyBroadcastGroup,
  getBroadcastGroupData,
  getFilteredBroadcastGroupData,
  getYoberyBroadcastGroup,
  patchYoberyBroadcastGroup,
  postYoberyBroadcastGroup,
} from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import WarningModal from "../../components/Modals/WarningModal";
import { toast, ToastContainer } from "react-toastify";
import PaginationControls from "../../services/PaginationControls";
import { BROADCASTGROUP } from "../../types/broadcastGroup";
import { filter } from "@chakra-ui/react";
import { TranslateErrorMessage } from "../../services/ErrorMessages";

/**
 * YobelyMainPage - A component for managing broadcast groups including viewing, adding, editing, and deleting groups.
 *
 * This component fetches a list of broadcast groups, displays them in a table format, and allows users to:
 * - Add a new group
 * - Edit an existing group
 * - Delete a group
 * - Search for groups by name
 * - Paginate through the list of groups
 *
 * State:
 * - `isEditing` (boolean): Indicates whether a new group is being added.
 * - `isUpdating` (boolean): Indicates whether an existing group is being edited.
 * - `newGroupName` (string): Holds the name of the group being added or edited.
 * - `editingIndex` (number | null): Index of the group currently being edited.
 * - `groups` (BROADCASTGROUP[]): Array of all broadcast groups.
 * - `searchTerm` (string): Holds the value of the search input field.
 * - `filteredGroups` (BROADCASTGROUP[]): Array of groups filtered by the search term.
 * - `deleteModalOpen` (boolean): Tracks whether the delete confirmation modal is open.
 * - `selectedGroupInformation` (object): Stores information about the group selected for deletion.
 * - `loading` (boolean): Indicates whether the component is in a loading state.
 * - `error` (string): Holds error messages encountered during API interactions.
 * - `currentPage` (number): Tracks the current page for pagination.
 * - `totalPages` (number): Total number of pages available for pagination.
 * - `perPage` (number): Number of items displayed per page.
 *
 * Functions:
 * - `handleAddGroup`: Initiates the process of adding a new group.
 * - `handleRemoveGroup`: Removes the last group from the filteredGroups list.
 * - `openDeleteModal`: Opens the delete confirmation modal with selected group information.
 * - `closeDeleteModal`: Closes the delete confirmation modal.
 * - `handleSave`: Saves a new group to the backend API and updates the group list.
 * - `handleDelete`: Deletes the selected group from the backend API and updates the group list.
 * - `handleEditGroup`: Sets the component state for editing an existing group.
 * - `handleUpdate`: Updates an existing group in the backend API.
 * - `doSearch`: Searches for groups by name and updates the filtered list.
 * - `fetchBroadcastGroups`: Fetches broadcast groups from the backend API based on the current page.
 * - `handlePageChange`: Updates the current page state and fetches the corresponding broadcast groups.
 * - `updateBroadcastGroup`: Updates the group name in the local filteredGroups state.
 *
 * API Interactions:
 * - `getYoberyBroadcastGroup`: Fetches broadcast group data from the backend API.
 * - `postYoberyBroadcastGroup`: Adds a new broadcast group to the backend API.
 * - `patchYoberyBroadcastGroup`: Updates an existing broadcast group in the backend API.
 * - `deleteYoberyBroadcastGroup`: Deletes a broadcast group from the backend API.
 * - `getBroadcastGroupData`: Fetches all broadcast groups from the backend API.
 * - `getFilteredBroadcastGroupData`: Fetches broadcast groups filtered by a search query from the backend API.
 *
 * Modals:
 * - `WarningModal`: A modal for confirming the deletion of a broadcast group.
 *
 * Toast Notifications:
 * - `errorToast`: Displays an error message using react-toastify.
 * - `successToast`: Displays a success message using react-toastify.
 *
 * Components:
 * - `TitleWithDivider`: A component for displaying a title with a divider.
 * - `SearchBar`: A component for displaying a search bar.
 * - `LoadingIcon`: A component for displaying a loading spinner when data is being fetched.
 * - `PaginationControls`: A component for managing pagination controls.
 * - `WarningModal`: A component for displaying a modal warning message.
 * - `ToastContainer`: A component for displaying toast notifications.
 *
 * Pagination:
 * - The `handlePageChange` function is used to navigate between pages of broadcast groups.
 * - The `fetchBroadcastGroups` function fetches the appropriate page of broadcast groups.
 *
 * Usage:
 * This component is used to manage broadcast groups by allowing users to view, add, edit, and delete groups.
 * It also supports searching and paginating through the list of broadcast groups.
 *
 * Example Usage:
 * <TalkMainPage />
 */

const YobelyMainPage: React.FC = () => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [groups, setGroups] = useState<BROADCASTGROUP[] | null>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredGroups, setFilteredGroups] = useState<BROADCASTGROUP[] | null>(
    [],
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedGroupInformation, setSelectedGroupInformation] = useState<{
    broadcast_group_id: string;
    broadcast_group_name: string;
  }>({
    broadcast_group_id: "",
    broadcast_group_name: "",
  });

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const errorToast = (response: string) => toast.error(`${response}`);
  const successToast = (response: string) => toast.success(`${response}`);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentGroups = filteredGroups?.slice(firstIndex, lastIndex) || [];

  const [totalPages, setTotalPages] = useState<number>(
    Math.ceil(groups?.length / itemsPerPage),
  );

  useEffect(() => {
    setTotalPages(Math.ceil(groups?.length / itemsPerPage));
  }, [itemsPerPage, totalPages]);

  useEffect(() => {
    setTotalPages(groups?.length || 0);
  }, [filteredGroups]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await getYoberyBroadcastGroup(setGroups, setError, setLoading);
      } catch (err) {
        console.error(err);
      } finally {
        setSearchTerm("");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleAddGroup = () => {
    if (!isEditing) {
      setEditing(true);
      const newGroup: {
        broadcast_group_id: number;
        broadcast_group_name: string;
      } = {
        broadcast_group_id: filteredGroups?.length + 1 || 1,
        broadcast_group_name: "",
      };
      const updatedGroups: BROADCASTGROUP[] = [
        ...(filteredGroups || []),
        newGroup,
      ];
      setGroups(updatedGroups);
      setFilteredGroups(updatedGroups);
    }
  };

  const handleRemoveGroup = () => {
    const updatedGroups: BROADCASTGROUP[] = filteredGroups.slice(0, -1);
    setGroups(updatedGroups);
    setFilteredGroups(updatedGroups);
    setEditing(false);
  };

  const openDeleteModal = (group) => {
    setSelectedGroupInformation({
      broadcast_group_id: group.broadcast_group_id,
      broadcast_group_name: group.broadcast_group_name,
    });
    setDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  const handleSave = async () => {
    let response;
    const trimmedName = newGroupName.trim();

    if (trimmedName) {
      try {
        setLoading(true);
        const broadcastGroup = { broadcast_group_name: trimmedName };
        await postYoberyBroadcastGroup(broadcastGroup, setError, setLoading)
          .then((response) => {
            if (response.status == 200) {
              successToast(response.message);
            } else {
              const errorMessage: string = TranslateErrorMessage(
                response.message,
              );
              console.error(errorMessage);
              setError(errorMessage);
              errorToast(errorMessage);
            }
          })
          .catch((error) => {
            const errorMessage: string = TranslateErrorMessage(
              response.message,
            );
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          });

        await getYoberyBroadcastGroup(setFilteredGroups, setError, setLoading);
      } catch (err) {
        setError("グループの保存に失敗しました。");
      } finally {
        setEditing(false);
        setNewGroupName("");
        setLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    await deleteYoberyBroadcastGroup(
      selectedGroupInformation,
      setLoading,
      setError,
    )
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
          setFilteredGroups([]);

          getYoberyBroadcastGroup(setFilteredGroups, setLoading, setError);
        } else {
          errorToast(response);
        }

        getYoberyBroadcastGroup(setGroups, setLoading, setError);

        setCurrentPage(1);
      })
      .catch((error) => {
        setError(error);
        errorToast(error);
      });

    closeDeleteModal();
  };

  const handleEditGroup = (index: number) => {
    setUpdating(true);
    setEditingIndex(index);
    setNewGroupName(filteredGroups[index].broadcast_group_name);
  };

  const handleUpdate = async (group) => {
    group.broadcast_group_name = newGroupName;
    await patchYoberyBroadcastGroup(group, setLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
          setNewGroupName("");
          setUpdating(false);
        } else {
          errorToast(response);
        }
      })
      .catch((error) => {
        setError(error);
        errorToast(error);
      });

    await getYoberyBroadcastGroup(setFilteredGroups, setLoading, setError);
  };

  useEffect(() => {
    if (searchTerm === "") {
      setFilteredGroups(groups);
    }
  }, [searchTerm, groups]);

  const doSearch = async () => {
    try {
      setLoading(true);
      if (!searchTerm) {
        setFilteredGroups(groups);
      } else {
        const query = `broadcast_group_name=${searchTerm}`;
        await getFilteredBroadcastGroupData(
          setFilteredGroups,
          setLoading,
          setError,
          query,
        );
      }
    } catch (error) {
      setError("検索に失敗しました。");
    } finally {
      setLoading(false);
    }
  };

  const fetchBroadcastGroups = (page = 1) => {
    getBroadcastGroupData(setFilteredGroups, setError, setLoading);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const updateBroadcastGroup = (event, index) => {
    filteredGroups[index] = event.target.value;
  };

  return (
    <div>
      <TitleWithDivider
        titleText="一斉発信グループ一覧・登録"
        useDivider={true}
      />
      <SearchBar
        searchBarText="キーワードを入力してください"
        buttonLeft="グループ検索"
        buttonRight=""
        displayRightButton={false}
        onSearch={doSearch}
        setSearchTerm={setSearchTerm}
      />
      <div className="flex items-center gap-2">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.9996 11V3M12.9996 7H20.9996M10.2266 13.8631C9.02506 12.6615 8.07627 11.3028 7.38028 9.85323C7.32041 9.72854 7.29048 9.66619 7.26748 9.5873C7.18576 9.30695 7.24446 8.96269 7.41447 8.72526C7.46231 8.65845 7.51947 8.60129 7.63378 8.48698C7.98338 8.13737 8.15819 7.96257 8.27247 7.78679C8.70347 7.1239 8.70347 6.26932 8.27247 5.60643C8.15819 5.43065 7.98338 5.25585 7.63378 4.90624L7.43891 4.71137C6.90747 4.17993 6.64174 3.91421 6.35636 3.76987C5.7888 3.4828 5.11854 3.4828 4.55098 3.76987C4.2656 3.91421 3.99987 4.17993 3.46843 4.71137L3.3108 4.86901C2.78117 5.39863 2.51636 5.66344 2.31411 6.02348C2.08969 6.42298 1.92833 7.04347 1.9297 7.5017C1.93092 7.91464 2.01103 8.19687 2.17124 8.76131C3.03221 11.7947 4.65668 14.6571 7.04466 17.045C9.43264 19.433 12.295 21.0575 15.3284 21.9185C15.8928 22.0787 16.1751 22.1588 16.588 22.16C17.0462 22.1614 17.6667 22 18.0662 21.7756C18.4263 21.5733 18.6911 21.3085 19.2207 20.7789L19.3783 20.6213C19.9098 20.0898 20.1755 19.8241 20.3198 19.5387C20.6069 18.9712 20.6069 18.3009 20.3198 17.7333C20.1755 17.448 19.9098 17.1822 19.3783 16.6508L19.1835 16.4559C18.8339 16.1063 18.6591 15.9315 18.4833 15.8172C17.8204 15.3862 16.9658 15.3862 16.3029 15.8172C16.1271 15.9315 15.9523 16.1063 15.6027 16.4559C15.4884 16.5702 15.4313 16.6274 15.3644 16.6752C15.127 16.8453 14.7828 16.904 14.5024 16.8222C14.4235 16.7992 14.3612 16.7693 14.2365 16.7094C12.7869 16.0134 11.4282 15.0646 10.2266 13.8631Z"
            stroke="#6E84A3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>

        <div className="link-text mb-10 mt-10" onClick={handleAddGroup}>
          グループ新規追加
        </div>
      </div>

      {loading ? (
        <LoadingIcon />
      ) : (
        <div className="bg-white border border-text-field-border rounded-xl">
          <table className="bg-white min-w-full rounded-xl p-5">
            <thead></thead>
            <tbody>
  {currentGroups?.length !== 0 ? (
    currentGroups.map((talkGroup, index) => (
      <tr
        key={index}
        className={`${
          index !== currentGroups.length - 1
            ? "border-b border-text-field-border"
            : ""
        }`}
      >
        <td className="p-2.5 xl:p-5 label-light-blue-xl items-center">
          {(isEditing || isUpdating) && index === editingIndex ? (
            <input
              type="text"
              className={`border rounded-xl px-4 py-2 ${
                isEditing ? "border-blue-500" : "border-text-field-border"
              } cursor-text`}
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              placeholder={isEditing ? "新しいグループ名" : "編集名前"}
            />
          ) : (
            <div className="flex justify-between items-center">
              <input
                type="text"
                disabled
                value={talkGroup.broadcast_group_name}
                className="justify-start pt-2 pb-2"
                onChange={(e) => updateBroadcastGroup(e, index)}
              />
              {!isEditing && !isUpdating && (
                <div className="flex items-center justify-end">
                  <button
                    className="button-small-white mt-4"
                    onClick={() => openDeleteModal(talkGroup)}
                  >
                    削除
                  </button>
                  <button
                    className="button-small-white mt-4"
                    onClick={() => handleEditGroup(index)}
                  >
                    編集
                  </button>
                </div>
              )}
            </div>
          )}
        </td>
        {(isEditing || isUpdating) && index === editingIndex && (
          <td className="p-2.5 xl:p-5 label-light-blue-xl items-center justify-end">
            <div className="flex items-center justify-end">
              <button
                onClick={() => {
                  isEditing && handleRemoveGroup();
                  setUpdating(false);
                  setNewGroupName("");
                }}
                className="button-small-white mt-4"
              >
                キャンセル
              </button>
              <button
                onClick={() => {
                  isUpdating ? handleUpdate(talkGroup) : handleSave();
                  setNewGroupName("");
                }}
                className="button-small-white mt-4"
              >
                保存
              </button>
            </div>
          </td>
        )}
      </tr>
    ))
  ) : (
    <tr>
      <td className="p-2.5 xl:p-5 form-label-text">
        {`グループはありません`}
      </td>
    </tr>
  )}
</tbody>
          </table>
          <WarningModal
            title={"一斉発信グループ削除"}
            body={"一斉発信グループを削除します。よろしいでしょうか？"}
            confirmText={"削除"}
            isOpen={deleteModalOpen}
            onConfirm={handleDelete}
            onRequestClose={closeDeleteModal}
          />
        </div>
      )}

      {!isEditing && !loading && (
        <PaginationControls
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          perPage={itemsPerPage}
          displayedItem={"グループ"}
          totalItems={groups?.length}
          setPerPage={setItemsPerPage}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default YobelyMainPage;
