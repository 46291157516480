import React, { useEffect, useRef, useState } from "react";

import {
  MinusCircleIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { getTenantDepartmentsData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import useClickOutside from "./ClickOutsideHandler";
import useDrag from "./DragHandler";
import { TENANTDEPARTMENT } from "../../types/tenantDepartment";
import { error_message } from "../../constants/Errors";

interface DepartmentModalProps {
  isOpen: boolean;
  nodeId: number;
  onRequestClose: () => void;
  onSelectGroup: (originalId: number, parentId: number) => void;
}

/**
 * ChangeDepartmentParentModal - A modal component for selecting a department from a hierarchical list.
 *
 * This component displays a modal dialog that allows users to select a department from a list.
 * It fetches department data when the modal is opened and displays it in a tree structure,
 * allowing for nested department selection. The user can expand or collapse parent nodes to
 * view or hide their child departments and select a department by clicking the "選択" button.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open.
 * @param {function} props.onRequestClose - A callback function to close the modal.
 * @param {function} props.onSelectGroup - A callback function called with the selected department data.
 * @returns {JSX.Element | null} - A JSX element representing the modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <DepartmentModal
 *   isOpen={isModalOpen}
 *   onRequestClose={closeModalHandler}
 *   onSelectGroup={handleSelectGroup}
 * />
 */

const ChangeDepartmentParentModal: React.FC<DepartmentModalProps> = ({
  isOpen,
  nodeId,
  onRequestClose,
  onSelectGroup,
}) => {
  const [tenantDepartment, setTenantDepartments] = useState<TENANTDEPARTMENT[]>(
    [],
  );
  const [originalTenantDepartments, setOriginalTenantDepartments] = useState(
    [],
  );
  const [error, setError] = useState<String>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const modalRef = useRef<HTMLDivElement>(null);

  useClickOutside(isOpen, onRequestClose, modalRef);
  const { position, handleMouseDown } = useDrag(modalRef, isOpen);

  const returnId = (originalId, parentId) => {
    onSelectGroup(originalId, parentId);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await getTenantDepartmentsData(
          setTenantDepartments,
          setOriginalTenantDepartments,
          setIsLoading,
          setError,
        );
      } catch (err) {
        setError(error_message.departments.load_failed);
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const buildTenantTree = (departments) => {
    const map = {};
    const roots = [];

    departments.forEach((dept) => {
      map[dept.id] = { ...dept, children: [] };
    });

    departments.forEach((dept) => {
      if (dept.parent === null) {
        roots.push(map[dept.id]);
      } else {
        if (map[dept.parent]) {
          map[dept.parent].children.push(map[dept.id]);
        }
      }
    });

    return roots;
  };

  const DepartmentTree = ({ depts }) => {
    const [expandedNodes, setExpandedNodes] = useState({});

    const toggleExpand = (id) => {
      setExpandedNodes((prevState) => ({
        ...prevState,
        [id]: !prevState[id],
      }));
    };

    const displayTree = (nodes) => {
      return (
        <ul>
          {nodes.map((node) => (
            <li key={node.id} className="node-item">
              <div className="flex justify-between items-center">
                {node.children.length !== 0 ? (
                  <div
                    className="flex justify-normal items-center"
                    onClick={() => toggleExpand(node.id)}
                  >
                    {expandedNodes[node.id] ? (
                      <MinusCircleIcon className="mr-5 w-5 h-5 text-mayo-link-text hover:opacity-50" />
                    ) : (
                      <PlusCircleIcon className="mr-5 w-5 h-5 text-mayo-link-text hover:opacity-50" />
                    )}
                    <div className="underline hover:opacity-50 cursor-pointer text-mayo-light-gray text-2xl">
                      [{node.display_order}] {node.name}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-normal items-center">
                    <div className="text-xl text-mayo-light-gray">
                      [{node.display_order}] {node.name}
                    </div>
                  </div>
                )}
                {node.id !== nodeId ? (
                  <button
                    className="button-small-white ml-2 items-end"
                    onClick={() => {
                      returnId(nodeId, node.id);
                    }}
                  >
                    選択
                  </button>
                ) : (
                  <div className="p-2 border rounded-xl bg-slate-400 text-white ">
                    選択中の部署
                  </div>
                )}
              </div>
              <div className="divider mt-2"></div>
              {node.children &&
                node.children.length > 0 &&
                expandedNodes[node.id] && (
                  <div className="ml-4 mt-2">{displayTree(node.children)}</div>
                )}
            </li>
          ))}
        </ul>
      );
    };

    const treeData = buildTenantTree(depts);

    return <div>{displayTree(treeData)}</div>;
  };

  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50"
      // ref={modalRef}
      // onMouseDown={handleMouseDown}
      // style={{
      //   transform: `translate(${position.x}px, ${position.y}px)`,
      // }}
    >
      <div className="bg-white border border-text-field-border rounded-lg w-1/2 max-h-1/2 overflow-y-auto">
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <h2 className="text-lg font-semibold">新しい部署の親を選択</h2>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon />
            </svg>
          </button>
        </div>
        {isLoading ? (
          <div className="mt-10">
            <LoadingIcon />
          </div>
        ) : tenantDepartment.length > 0 ? (
          <div className="mayo-card-body-modal overflow-y-auto max-h-[60vh] p-4">
            <DepartmentTree depts={tenantDepartment} />
          </div>
        ) : (
          <p className="flex items-center justify-center mt-5">
            部署選択がありません
          </p>
        )}
        <div className="flex justify-end gap-4 mb-5 mt-5 mr-4">
          <button onClick={onRequestClose} className="button-small-white">
            {"キャンセル"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangeDepartmentParentModal;
