import React from "react";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";
import PhonebookProfileForm from "../../components/Forms/PhonebookProfileForm";

const ViewContactPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="電話帳登録・編集画面" useDivider={true} />
        <PhonebookProfileForm></PhonebookProfileForm>
      </div>
    </>
  );
};

export default ViewContactPage;
