import React from "react";

interface PageNumbersProps {
  currentPage: number;
  totalPages: number;
  perPage: number;
  totalItems: number | undefined;
  displayedItem: string;
  setPerPage: (num: number) => void;
  onPageChange: (page: number) => void;
}

const PaginationControls: React.FC<PageNumbersProps> = ({
  currentPage,
  totalPages,
  perPage,
  displayedItem,
  totalItems,
  setPerPage,
  onPageChange,
}) => {
  const getPageNumbers = () => {
    const maxDisplay = 5;
    const pageNumbers = [];

    pageNumbers.push({ type: "page", value: 1 });

    if (currentPage > 3) {
      pageNumbers.push({ type: "dots", direction: "backward" });
    }

    const startPage = Math.max(
      Math.min(currentPage - 2, totalPages - maxDisplay + 1),
      2,
    );
    const endPage = Math.min(startPage + maxDisplay - 1, totalPages - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push({ type: "page", value: i });
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push({ type: "dots", direction: "forward" });
    }

    if (totalPages > 1 && endPage < totalPages) {
      pageNumbers.push({ type: "page", value: totalPages });
    }

    return pageNumbers;
  };

  const handlePreviousPages = () => {
    onPageChange(Math.max(currentPage - 3, 1));
  };

  const handleNextPages = () => {
    onPageChange(Math.min(currentPage + 3, totalPages));
  };

  const handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPerPage(parseInt(e.target.value));
  };

  const getDisplayedItemsCount = () => {
    if (totalItems === 0) return `0`;

    const startIndex = (currentPage - 1) * perPage + 1;
    const endIndex = Math.min(currentPage * perPage, totalItems);

    return `${startIndex}〜${endIndex}`;
  };

  return (
    <div className="flex flex-col items-center mt-4">
      <div className="flex justify-center mb-4 space-x-2">
        {getPageNumbers().map((item, index) => (
          <button
            key={index}
            onClick={() => {
              if (item.type === "page") {
                onPageChange(item.value);
              } else if (item.type === "dots") {
                if (item.direction === "backward") {
                  handlePreviousPages();
                } else if (item.direction === "forward") {
                  handleNextPages();
                }
              }
            }}
            disabled={currentPage === item.value}
            className={`w-10 ${
              currentPage === item.value
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-black"
            } rounded-xl hover:opacity-50`}
          >
            {item.type === "page" ? item.value : "..."}
          </button>
        ))}
      </div>

      <div className="flex items-center space-x-2">
        <select
          id="perPage"
          value={perPage}
          onChange={handlePerPageChange}
          className="p-1 bg-white border border-gray-300 rounded-xl hover:cursor-pointer"
        >
          <option value={1}>1件</option>
          <option value={2}>2件</option>
          <option value={5}>5件</option>
          <option value={10}>10件</option>
          <option value={25}>25件</option>
          <option value={50}>50件</option>
          <option value={100}>100件</option>
        </select>
      </div>
      <p className="flex justify-end w-full text-gray-600 ">
        {`${getDisplayedItemsCount()} / ${totalItems} ${displayedItem}`}
      </p>
    </div>
  );
};

export default PaginationControls;
