import { Bars3Icon } from "@heroicons/react/16/solid";
import {
  ChevronDoubleLeftIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import React, { Dispatch, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetUserId } from "../../services/StateManagement/UserSlice";
import { resetAuthState } from "../../services/StateManagement/AuthSlice";
import { myUserWithPasswordItems } from "./SideBarItems";
import { useKeycloak } from "@react-keycloak/web";
import { UnknownAction } from "@reduxjs/toolkit";

interface SidebarItem {
  key: string;
  title: string;
  icon: JSX.Element;
  path?: string;
  state?: any;
  children?: SidebarItem[];
}

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: (arg: boolean) => void;
  items: SidebarItem[];
}

/**
 * Sidebar - A sidebar component for navigation and user actions.
 *
 * This component displays a sidebar menu with expandable items and a logout button.
 * It supports opening and closing via a toggle button, and provides nested navigation
 * for items with children. The sidebar can be customized with different sets of items
 * passed as props. It also manages state for expanded items and the sidebar itself.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.sidebarOpen - A flag indicating whether the sidebar is open.
 * @param {function} props.setSidebarOpen - A callback function to toggle the sidebar open state.
 * @param {SidebarItem[]} props.items - An array of sidebar items to be displayed.
 * @returns {JSX.Element} - A JSX element representing the sidebar.
 *
 * SidebarItem shape:
 * @typedef {Object} SidebarItem
 * @property {string} key - A unique key for the sidebar item.
 * @property {string} title - The title of the sidebar item.
 * @property {JSX.Element} icon - An icon to display next to the item title.
 * @property {string} [path] - The navigation path for the item (optional).
 * @property {any} [state] - Additional state to pass when navigating to the path (optional).
 * @property {SidebarItem[]} [children] - An array of child items for nested navigation (optional).
 *
 * Usage:
 * <Sidebar
 *   sidebarOpen={isSidebarOpen}
 *   setSidebarOpen={setSidebarOpen}
 *   items={sidebarItems}
 * />
 */

const Sidebar = ({ sidebarOpen, setSidebarOpen, items }: SidebarProps) => {
  const { keycloak } = useKeycloak();
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  const userId: string = useSelector((state) => state.user.userId);
  const isSysAdminRole: boolean = useSelector((state) => state.auth.isAdmin);
  const isFirstUser: boolean = useSelector((state) => state.auth.isFirstUser);

  const trigger = useRef<any>(null);
  const sidebar = useRef<any>(null);

  const storedSidebarExpanded: string | null =
    localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true",
  );

  const [expandedItems, setExpandedItems] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleItem = (key: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleLogout = () => {
    dispatch(resetUserId());
    dispatch(resetAuthState());

    localStorage.removeItem("userInfo");
    localStorage.setItem("logInState", "logging_out");
    keycloak.logout({ redirectUri: window.location.origin });
  };

  const renderSidebarItems = (items: SidebarItem[]) => {
    return items.map((item) => (
      <li key={item.key} className="relative">
        <div onClick={() => item.children && toggleItem(item.key)}>
          <NavLink
            to={item.path || "#"}
            state={item.state}
            className="pt-group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark"
          >
            {item.icon}
            {item.title}
            {item.children && (
              <span className="ml-auto">
                {expandedItems[item.key] ? (
                  <ChevronUpIcon className="w-4 h-4" />
                ) : (
                  <ChevronDownIcon className="w-4 h-4" />
                )}
              </span>
            )}
          </NavLink>
        </div>
        {item.children && expandedItems[item.key] && (
          <ul className="ml-4 mt-2">{renderSidebarItems(item.children)}</ul>
        )}
      </li>
    ));
  };

  return (
    <>
      <div>
        <button
          ref={trigger}
          onClick={() => setSidebarOpen(!sidebarOpen)}
          aria-controls="sidebar"
          aria-expanded={sidebarOpen}
          className="lg:hidden p-4 bg-gradient-to-r bg-mayo-bg  text-white"
        >
          <div className="bg-slate-200 rounded-xl p-2 ">
            <Bars3Icon className="w-6 h-6 text-black" />
          </div>
        </button>
      </div>

      <aside
        ref={sidebar}
        className={`absolute left-0 top-0 z-9999 flex h-screen w-72.5 flex-col overflow-y-hidden bg-side-bar-dark duration-300 ease-linear lg:static lg:translate-x-0 ${
          sidebarOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div>
          <button
            ref={trigger}
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
            className="block lg:hidden"
          >
            <svg
              className="fill-current ml-5 mt-5"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <ChevronDoubleLeftIcon className="text-white"></ChevronDoubleLeftIcon>
            </svg>
          </button>
        </div>

        <div className="no-scrollbar flex flex-col overflow-y-auto duration-300 ease-linear">
          <nav className="mt-5 py-4 px-4 lg:mt-9 lg:px-6">
            <ul className="mb-6 flex flex-col gap-1.5">
              {renderSidebarItems(items)}

              <div className="mt-5 divider"></div>
              {isSysAdminRole === false &&
                isFirstUser === false &&
                renderSidebarItems(myUserWithPasswordItems(userId))}
              <li>
                <div
                  onClick={handleLogout}
                  className="pt-group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark"
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12 12.7502L15.75 9.00023M15.75 9.00023L12 5.25023M15.75 9.00023H6.75M9 12.7502C9 12.9719 9 13.0828 8.99176 13.1788C8.90612 14.1767 8.17114 14.9979 7.18878 15.1932C7.09427 15.212 6.98401 15.2242 6.76376 15.2487L5.99771 15.3338C4.84686 15.4617 4.27141 15.5256 3.81425 15.3793C3.2047 15.1843 2.70706 14.7389 2.44588 14.1546C2.25 13.7164 2.25 13.1374 2.25 11.9795V6.021C2.25 4.86305 2.25 4.28408 2.44588 3.84587C2.70706 3.26159 3.2047 2.81618 3.81425 2.62112C4.27141 2.47483 4.84684 2.53877 5.99771 2.66664L6.76376 2.75176C6.98409 2.77624 7.09425 2.78848 7.18878 2.80727C8.17114 3.0026 8.90612 3.82376 8.99176 4.82169C9 4.91771 9 5.02855 9 5.25023"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  ログアウト
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
