import React, { useState, ChangeEvent, useRef } from "react";
import Papa from "papaparse";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TitleWithDivider } from "./TitleWithDivider";
import { ConfirmCancelButtons } from "../components/Buttons/ConfirmCancelButtons";
import FailedUsersModal from "../components/Modals/FailedUsersModal";
import { USER } from "../types/user";
import Encoding from "encoding-japanese";
import { translateAndReorderHeaders } from "../services/TranslateCsvHeaders";
import { error_message } from "../constants/Errors";
import messages from "../constants/Messages";

interface CsvPageProps {
  expectedHeaders: string[];
  uploadHandler: () => void;
  cancelText?: string;
  titleText?: string;
  failedUsers?: [];
  onCancel: () => void;
  sampleData: USER[];
  templateName: string;
  csvData: any[];
  setCsvData: (data: any[]) => void;
  headerMapping: [];
  fromUserPage?: boolean;
}

export const CsvUploader: React.FC<CsvPageProps> = ({
  expectedHeaders,
  uploadHandler,
  cancelText = "Cancel",
  titleText = "CSVアップロード",
  failedUsers = [],
  onCancel,
  templateName,
  sampleData,
  csvData,
  setCsvData,
  headerMapping,
  fromUserPage = false,
}) => {
  const fileInput = useRef<HTMLInputElement | null>(null);

  const [failedUsersModalOpen, setFailedUsersOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [fileReadText, setFileReadText] =
    useState("ファイルが選択されていません");

  const errorToast = (response) => toast.error(`${response}`);

  const headerError = () =>
    toast.error("CSVファイルのヘッダーが正しくありません。");
  const noCsvImportError = () => toast.error("CSVデータがありません。");

  const createReverseMapping = (mapping: { [key: string]: string }) => {
    return Object.fromEntries(
      Object.entries(mapping).map(([key, value]) => [value, key]),
    );
  };

  const reverseHeaderMapping = createReverseMapping(headerMapping);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(event.target.files || []);
    setFiles(selectedFiles);

    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = () => {
        const arrayBuffer = reader.result as ArrayBuffer;
        const uint8Array = new Uint8Array(arrayBuffer);
        const encoding = Encoding.detect(uint8Array);

        let decodedCsv;
        if (encoding === "SJIS" || encoding === "SHIFT_JIS") {
          const unicodeArray = Encoding.convert(uint8Array, {
            from: "SJIS",
            to: "UNICODE",
            type: "array",
          });
          decodedCsv = Encoding.codeToString(unicodeArray);
        } else {
          decodedCsv = new TextDecoder("utf-8").decode(uint8Array);
        }

        Papa.parse(decodedCsv, {
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            const parsedHeaders = results.meta.fields;

            const expectedHeadersFromMapping = Object.values(headerMapping);

            const unmatchedHeaders = parsedHeaders?.filter(
              (header) => !expectedHeadersFromMapping.includes(header),
            );
            if (unmatchedHeaders && unmatchedHeaders.length > 0) {
              console.warn("unmatched headers found in CSV:", unmatchedHeaders);
              headerError();
              setCsvData([]);
              return;
            }

            const nullField = results.data.map((row: any, index: number) => {
              const errors = validateNullFields(row, index);

              if (errors) {
                Object.values(errors).forEach((error) => {
                  errorToast(error);
                });
                return true;
              } else {
                return false;
              }
            });

            if (nullField.includes(true)) {
              return;
            }

            const translatedData = results.data.map(
              (row: any, index: number) => {
                const translatedRow: { [key: string]: any } = { index };
                parsedHeaders.forEach((header) => {
                  const englishHeader = reverseHeaderMapping[header] || header;
                  translatedRow[englishHeader] = row[header] || "";
                });
                return translatedRow;
              },
            );
            setCsvData(translatedData);
            setFileReadText(`${file.name}のプレビュー`);
          },
          error: (error) => {
            console.error(error_message.csv.parse_error, error);
            toast.error(error_message.csv.parse_error);
            setCsvData([]);
            setFiles([]);
          },
        });
      };

      reader.onerror = () => {
        console.error(error_message.csv.loading);
        toast.error(error_message.csv.loading);
      };
    }
  };

  const validateNullFields = (user, index) => {
    const errors = {};
    const excludedKeys = [
      "利用アプリ",
      "かな（名）",
      "かな（名）",
      "英字（姓）（任意）",
      "英字（名）（任意）",
      "社員番号（任意)",
      "メールアドレス（任意）",
      "役職名",
      "企業名",
      "企業電話番号",
      "企業ホームページ",
    ];

    Object.keys(user).forEach((key) => {
      if (
        (!user[key] || user[key].toString().trim() === "") &&
        !excludedKeys.includes(key)
      ) {
        errors[key] = `${index + 1}行: ${key}が必要です`;
      }
    });

    return Object.keys(errors).length > 0 ? errors : null;
  };

  const translateSampleData = (
    sampleData: USER[],
    mapping: { [key: string]: string },
  ) => {
    return sampleData.map((row) => {
      const translatedRow: { [key: string]: any } = {};

      Object.keys(mapping).forEach((key) => {
        if (key in row) {
          translatedRow[mapping[key]] = row[key];
        }
      });

      return translatedRow;
    });
  };

  const handleDownloadSampleCsv = () => {
    const translatedSampleData = translateSampleData(sampleData, headerMapping);

    const filteredHeaders = Object.values(headerMapping);

    const csvString = Papa.unparse(translatedSampleData, {
      columns: filteredHeaders,
    });

    const sjisArray = Encoding.stringToCode(csvString);
    const encodedArray = Encoding.convert(sjisArray, {
      from: "UNICODE",
      to: "SJIS",
    });
    const uint8Array = new Uint8Array(encodedArray);

    const blob = new Blob([uint8Array], {
      type: "text/csv;charset=Shift_JIS;",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${templateName}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const translatedHeaders = translateAndReorderHeaders(
    expectedHeaders,
    headerMapping,
  );

  const removeEntryFromCsv = (originalIndex) => {
    setCsvData((prev) => {
      return prev.filter((user) => user.index !== originalIndex);
    });
  };

  return (
    <div>
      <TitleWithDivider titleText={titleText} useDivider={true} />
      <div className="mt-4 flex justify-between">
        <button
          className="p-3 mb-5 bg-button-light-blue text-white rounded-lg hover:opacity-80"
          onClick={handleDownloadSampleCsv}
        >
          テンプレートCSVをダウンロード
        </button>
        {failedUsers.length > 0 && (
          <button
            className="ml-5 mb-2 pl-2 pr-2 items-center justify-center bg-button-light-blue text-white rounded-xl hover:opacity-80"
            onClick={() => setFailedUsersOpen(true)}
          >
            失敗したユーザーを表示
          </button>
        )}
      </div>
      <div
        className={`flex flex-col p-5 bg-white rounded-lg border border-divider ${
          csvData.length > 0 ? "min-h-30" : ""
        }`}
      >
        <div className="flex">
          <button
            className="p-1 bg-button-light-blue text-white rounded-xl hover:opacity-80"
            onClick={() => fileInput.current?.click()}
          >
            ファイルを選択
          </button>
          <div className="p-5 bg-white rounded-xl mr-5 max-w-full break-words whitespace-pre-line">
            {fileReadText}
          </div>
          <input
            type="file"
            onChange={handleFileChange}
            accept=".csv"
            ref={fileInput}
            style={{ display: "none" }}
          />
        </div>
        <div className="mt-4 overflow-auto">
          {csvData.length > 0 && (
            <table className="min-w-full bg-white table-fixed">
              <thead>
                <tr>
                  {translatedHeaders
                    .filter((header) => header !== "index")
                    .map((header, index) => (
                      <th
                        key={index}
                        className="py-1 px-1 border-b border-gray-200 text-left min-w-[200px]"
                      >
                        {header}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {csvData.slice(0, 10).map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {Object.keys(row)
                      .filter((key) => key !== "index")
                      .map((key, cellIndex) => (
                        <td
                          key={cellIndex}
                          className="pl-2 py-2 px-4 border-b border-gray-200 text-left min-w-[200px]"
                        >
                          {row[key] || ""}
                        </td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {fromUserPage && (
        <div>
          <p className="mt-5">{messages.csv.hint}</p>
          <p className="ml-10 mt-5">※ 例： 利用アプリ</p>
          <p className="ml-18">YOBELY,KAKELY</p>
        </div>
      )}
      <ConfirmCancelButtons
        confirmText={"インポート"}
        onConfirm={csvData.length === 0 ? noCsvImportError : uploadHandler}
        onCancel={onCancel}
      />
      <ToastContainer />
      <FailedUsersModal
        removeFromCsv={removeEntryFromCsv}
        failedUsers={failedUsers}
        confirmText={""}
        isOpen={failedUsersModalOpen}
        onRequestClose={() => setFailedUsersOpen(false)}
      />
    </div>
  );
};
