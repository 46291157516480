import React, { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CsvUploader } from "../../common/UploadCsvTemplate";
import { postContactArray } from "../../services/API/Requests";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import LoadingIcon from "../../common/LoadingIcon";
import { GetCurrentDate } from "../../services/Utilities";
import { contactHeaderMapping } from "../../services/TranslateCsvHeaders";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";
import { USER_TYPE } from "../../constants/Enums";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";

/**
 * ContactCsvUploadPage - A page component for uploading contact information through a CSV file.
 *
 * This page provides functionality to upload a CSV file containing contact information, validates the
 * CSV headers, and sends the data to the server for storage. It also provides a sample CSV template
 * for download and shows toast notifications for various upload statuses.
 *
 * State:
 * - `loading` (boolean): A state to track if the data is being uploaded to the server.
 * - `error` (string): A state to store any error messages encountered during the upload process.
 * - `csvData` (Array<object>): An array of objects representing the parsed CSV data to be uploaded.
 *
 * Props:
 * - `expectedHeaders` (string[]): An array of strings representing the required headers for the CSV file.
 * - `sampleData` (Array<object>): Sample data to be included in the downloadable CSV template.
 * - `templateName` (string): The name of the template file to be downloaded.
 *
 * Functions:
 * - `handleCsvUpload`: Handles the CSV upload process. Validates and sends each user object to the server.
 * - `onCancel`: Navigates back to the "connect" page without performing any action.
 *
 * Toast Notifications:
 * - `noCsvImportError`: Shows a toast notification when the CSV data is empty.
 * - `uploadCsvDataToast`: Shows a toast notification when the CSV data is successfully uploaded.
 * - `failCsvDataToast`: Shows a toast notification when there's an error during CSV data upload.
 *
 * API Interactions:
 * - `postContact`: Sends the user data to the server for each entry in the CSV.
 *
 * Usage:
 * This component uses the `CsvUploader` component to handle file selection, preview, and validation,
 * and provides custom upload logic for contact data.
 *
 * Example Usage:
 * <ContactCsvUploadPage />
 */

/**
 * ContactCsvUploadPage - A page component for uploading contact information through a CSV file.
 *
 * This page provides functionality to upload a CSV file containing contact information, validates the
 * CSV headers, and sends the data to the server for storage. It also provides a sample CSV template
 * for download and shows toast notifications for various upload statuses.
 *
 * State:
 * - `loading` (boolean): A state to track if the data is being uploaded to the server.
 * - `error` (string): A state to store any error messages encountered during the upload process.
 * - `csvData` (Array<object>): An array of objects representing the parsed CSV data to be uploaded.
 *
 * Props:
 * - `expectedHeaders` (string[]): An array of strings representing the required headers for the CSV file.
 * - `sampleData` (Array<object>): Sample data to be included in the downloadable CSV template.
 * - `templateName` (string): The name of the template file to be downloaded.
 *
 * Functions:
 * - `handleCsvUpload`: Handles the CSV upload process. Validates and sends each user object to the server.
 * - `onCancel`: Navigates back to the "KAKELY" page without performing any action.
 *
 * Toast Notifications:
 * - `noCsvImportError`: Shows a toast notification when the CSV data is empty.
 * - `uploadCsvDataToast`: Shows a toast notification when the CSV data is successfully uploaded.
 * - `failCsvDataToast`: Shows a toast notification when there's an error during CSV data upload.
 *
 * API Interactions:
 * - `postContact`: Sends the user data to the server for each entry in the CSV.
 *
 * Usage:
 * This component uses the `CsvUploader` component to handle file selection, preview, and validation,
 * and provides custom upload logic for contact data.
 *
 * Example Usage:
 * <ContactCsvUploadPage />
 */

const ContactCsvUploadPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const currentDate = GetCurrentDate();

  const sampleData = [
    {
      first_name: "花子",
      last_name: "佐藤",
      first_kana_name: "ハナコ",
      last_kana_name: "サトウ ",
      first_furigana_name: "SATO",
      last_furigana_name: "HANAKO",
      email: "hanako.sato@example.com",
      position_name: "人事部マネージャー",
      company_name: "テスト株式会社",
      company_tel: "09023456789",
      personal_tel: "09023456789",
      company_address: "東京都千代田区神田1-2-3",
      company_home_page: "https://www.exsample.com",
    },
  ];

  const expectedHeaders = [
    "漢字（名）",
    "漢字（姓）",
    "かな（名）（任意）",
    "かな（姓）（任意）",
    "英字（名）（任意）",
    "英字（姓）（任意）",
    "メールアドレス（任意）",
    "役職名（任意）",
    "企業名（任意）",
    "企業の電話番号（任意）",
    "電話番号（任意）",
    "企業の住所（任意）",
    "企業のホームページ（任意）",
  ];

  const noCsvImportError = () => toast.error(error_message.csv.no_data);
  const uploadCsvDataToast = (message) => toast.success(message);
  const failCsvDataToast = (error) => toast.error(`${error}`);
  const [csvData, setCsvData] = useState<[]>([]);
  const [failedUsers, setFailedUsers] = useState<[]>();

  const handleCsvUpload = async () => {
    console.log("running handle upload");
    if (csvData.length === 0) {
      noCsvImportError();
      return;
    }

    const userDataArray = csvData.map((userInfo) => {
      const user = {
        last_name: userInfo.last_name,
        first_name: userInfo.first_name,
        last_kana_name: userInfo.last_kana_name,
        first_kana_name: userInfo.first_kana_name,
        last_furigana_name: userInfo.last_furigana_name,
        first_furigana_name: userInfo.first_furigana_name,
        email: userInfo.email,
        user_type: USER_TYPE.EXTERNAL,
        is_display: userInfo.is_display,
        position_name: userInfo.position_name,
        company_name: userInfo.company_name,
        company_tel: userInfo.company_tel,
        personal_tel: userInfo.personal_tel,
        company_address: userInfo.company_address,
        company_home_page: userInfo.company_home_page,
      };

      return { userInfo: user };
    });

    const postUserInformation = async (userDataArray) => {
      let response;
      try {
        response = await postContactArray(userDataArray, setLoading, setError);
        if (
          response.responseMessage.status >= 300 ||
          response.responseMessage.status == null
        ) {
          const errorMessage = TranslateErrorMessage(response.responseMessage);
          setFailedUsers(response.failed_users);
          console.error(errorMessage);
          setError(errorMessage);
          failCsvDataToast(errorMessage);
          return;
        } else {
          uploadCsvDataToast(
            messages.user.uploaded(
              response.created_users?.length,
              response.failed_users?.length,
            ),
          );
        }
        setFailedUsers(response.failed_users);
      } catch (error) {
        if (response && response.failed_users) {
          setFailedUsers(response.failed_users);
        }
        const errorMessage = TranslateErrorMessage(response.responseMessage);
        console.error(errorMessage);
        setError(errorMessage);
        failCsvDataToast(errorMessage);
      }
    };
    await postUserInformation(userDataArray);
  };

  const onCancel = () => {
    navigate(KAKELY_PATHS.main);
  };

  return (
    <div className="relative">
      {loading && (
        <div className="absolute inset-0 flex items-center justify-center z-50">
          <div className="bg-white bg-opacity-80 p-5 rounded-lg shadow-lg flex items-center justify-center">
            <LoadingIcon />
          </div>
        </div>
      )}
      <CsvUploader
        titleText="電話帳CSVアップロード"
        expectedHeaders={expectedHeaders}
        uploadHandler={handleCsvUpload}
        onCancel={onCancel}
        csvData={csvData}
        setCsvData={setCsvData}
        sampleData={sampleData}
        failedUsers={failedUsers}
        templateName={`電話帳用CSVサンプル_${currentDate}`}
        headerMapping={contactHeaderMapping}
      />
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default ContactCsvUploadPage;
