import { useEffect, useMemo, useRef, useState } from "react";
import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";

import { useKeycloak } from "@react-keycloak/web";
import {
  setAuthState,
  setIsAdmin,
  setIsFirstUser,
} from "./services/StateManagement/AuthSlice";
import { useDispatch, useSelector } from "react-redux";

import { setCompanyIdState } from "./services/StateManagement/CompanySlice";
import { setUserId } from "./services/StateManagement/UserSlice";
import { setHeaders } from "./services/StateManagement/ApiSlice";

import Loader from "./common/Loader";
import PageTitle from "./common/PageTitle";
import React from "react";

import DefaultLayout from "./layout/DefaultLayout";

import NotificationMainPage from "./pages/Notification/NotificationMainPage";
import ViewNotificationPage from "./pages/Notification/ViewNotificationPage";
import CreateNotificationPage from "./pages/Notification/CreateNotificationPage";

import ViewTenantPage from "./pages/Tenant/ViewTenantPage";

import HubMainPage from "./pages/WORKJOY/HubMainPage";
import HomeMainPage from "./pages/Home/HomeMainPage";

import CreateUserPage from "./pages/User/CreateUserPage";
import KakelyCsvUploadPage from "./pages/KAKELY/KakelyCsvUploadPage";
import CreatePhonebookPage from "./pages/KAKELY/CreatePhonebookPage";

import UserMainPage from "./pages/User/UserMainPage";
import ViewPhonebookPage from "./pages/KAKELY/ViewPhonebookPage";
import ViewUserPage from "./pages/User/ViewUserPage";
import ViewCurrentUserPage from "./pages/User/ViewUserPage";
import UserCSVUploadPage from "./pages/User/UserCsvUploadPage";
import UserPhotoUploadPage from "./pages/User/UserPhotoUploadPage";
import PasswordResetPage from "./pages/User/PasswordResetPage";

import AISettingsPage from "./pages/WORKJOY/AISettingsPage";
import BiToolPage from "./pages/WORKJOY/BiToolPage";

import YobelyMainPage from "./pages/YOBELY/YobelyMainPage";
import CreateTenantPage from "./pages/Tenant/CreateTenantPage";

import DepartmentGroupMainPage from "./pages/KAKELY/DepartmentGroupMainPage";
import EditDepartmentGroupPage from "./pages/KAKELY/EditDepartmentGroupPage";
import CreateDepartmentGroupPage from "./pages/KAKELY/CreateDepartmentGroupPage";

import StampSettingsPage from "./pages/WORKJOY/StampSettingsPage";
import MentalHealthPage from "./pages/WORKJOY/MentalHealthPage";

import PrivateRoute from "./components/Routes/PrivateRoute";
import NotFound from "./components/ErrorPages/PageNotFound";
import AdminRoute from "./components/Routes/AdminRoute";
import TenantAdminPage from "./pages/Tenant/TenantAdminPage";
import ContractRoute from "./components/Routes/ContractRoute";
import KakelyMainPage from "./pages/KAKELY/KakelyMainPage";
import useApiConnection from "./services/CheckConnection";
import NoConnectionModal from "./components/Modals/NoConnectionModal";
import RegularRoute from "./components/Routes/RegularRoute";
import { error_message } from "./constants/Errors";
import messages from "./constants/Messages";
import {
  COMPANY_PATHS,
  GENERIC_PATHS,
  KAKELY_PATHS,
  NOTIFICATION_PATHS,
  USER_PATHS,
  WORKJOY_PATHS,
  YOBELY_PATHS,
} from "./constants/NavigationPaths";

function App() {
  const { pathname } = useLocation();
  const { keycloak, initialized } = useKeycloak();
  const params = useParams();
  const dispatch = useDispatch();

  const APPNAME: string = import.meta.env.VITE_APP_NAME;
  const adminRealm: string = import.meta.env.VITE_ADMIN_REALM;
  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);
  const realm: string | null = localStorage.getItem("realm");

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<String>("");

  const [sessionExpired, setSessionExpired] = useState<boolean>(false);

  const [isConnected, setIsConnected] = useState<boolean | undefined>(false);

  const [connectionChecked, setConnectionChecked] = useState<boolean>(false);

  useEffect(() => {
    if (localStorage.getItem("logInState") == "logged_in") {
      localStorage.setItem("previousState", "logging_in");
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);

  useEffect(() => {
    const checkConnection = async () => {
      if (connectionChecked) {
        return;
      }
      try {
        const connectionStatus = await useApiConnection();
        if (connectionStatus !== null) {
          setIsConnected(connectionStatus);
        }
        setConnectionChecked(true);
      } catch (err) {
        setIsConnected(false);
        setConnectionChecked(false);
      }
    };

    const handleUserSettings = async () => {
      if (!keycloak || !keycloak.token) return;

      dispatch(
        setHeaders({
          Authorization: `Bearer ${keycloak.token}`,
        }),
      );

      try {
        const userInfo: {} = await keycloak.loadUserInfo();

        if (userInfo) {
          dispatch(setAuthState(true));
          dispatch(setCompanyIdState(keycloak.realm));
          dispatch(setUserId(userInfo?.sub));

          const userGroups: string[] | undefined =
            keycloak.tokenParsed?.realm_access?.roles;

          if (userGroups?.includes("admins")) {
            if (realm === adminRealm) {
              if (!isAdminLoggedIn) {
                dispatch(setIsAdmin(true));
              }
            } else {
              dispatch(setIsFirstUser(true)); // track the first user, since we don't have login details to display
            }
          } else {
            dispatch(setIsFirstUser(false)); // track the first user, since we don't have login details to display
          }

          const refreshTokenInterval = setInterval(async () => {
            try {
              await keycloak.updateToken().then(() => {
                dispatch(
                  setHeaders({
                    Authorization: `Bearer ${keycloak.token}`,
                  }),
                );
              });
            } catch (err) {
              console.error(error_message.keycloak.token_refresh, err);
            }
          }, 30000);

          return () => clearInterval(refreshTokenInterval);
        }
      } catch (err) {
        console.error(error_message.user.not_loaded, err);
      }
    };

    handleUserSettings();
    checkConnection();
  });

  useEffect(() => {
    if (sessionExpired) {
      localStorage.setItem("logInState", "logging_out");
    }
  }, [sessionExpired]);

  const checkTokenExpiration = () => {
    if (keycloak && keycloak.authenticated) {
      console.info(messages.keycloak.token_authed);
    } else {
      console.warn(error_message.generic.session_expired);
      setSessionExpired(true);
    }
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      if (!initialized) {
        console.info(messages.keycloak.wait_for_init);
        return;
      }

      if (keycloak.authenticated) {
        localStorage.setItem("logInState", "logged_in");
        checkTokenExpiration();
      } else {
        if (localStorage.getItem("logInState") === "logged_in") {
          localStorage.setItem("logInState", "logging_out");
        } else {
          localStorage.setItem("logInState", "logging_in");
          await keycloak.login();
        }
      }
    };

    if (initialized) {
      checkAuthentication();
    }
  }, [initialized, keycloak]);

  if (
    connectionChecked === true &&
    isConnected === false &&
    localStorage.getItem("logInState") === "logged_in"
  ) {
    return <NoConnectionModal />;
  }

  return loading ? (
    <Loader />
  ) : (
    <DefaultLayout>
      <Routes>
        <Route
          index
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`ログイン | ${APPNAME}`} />
                  {isAdminLoggedIn ? <UserMainPage /> : <HomeMainPage />}
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.main}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`お知らせ一覧 | ${APPNAME}`} />
                  <NotificationMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.newNotification}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`お知らせ作成 | ${APPNAME}`} />
                  <CreateNotificationPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.viewNotification}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`お知らせ登録・編集 | ${APPNAME}`} />
                  <ViewNotificationPage />
                </>
              )}
            />
          }
        />
        <Route
          path={NOTIFICATION_PATHS.createNotification}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`お知らせ登録・編集 | ${APPNAME}`} />
                      <CreateNotificationPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.listCompanies}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`企業一覧 | ${APPNAME}`} />
                      <TenantAdminPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.showCompany}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`企業情報管理 | ${APPNAME}`} />
                  <ViewTenantPage />
                </>
              )}
            />
          }
        />
        <Route
          path={COMPANY_PATHS.manageCompany}
          element={
            <AdminRoute
              element={() => (
                <>
                  <PrivateRoute
                    element={() => (
                      <>
                        <PageTitle title={`企業情報管理 | ${APPNAME}`} />
                        <ViewTenantPage />
                      </>
                    )}
                  />
                </>
              )}
            />
          }
        />
        {/* <Route
          path="company/manage/csvUpload"
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`企業CSVアップロード | ${APPNAME}`} />
                      <TenantCsvUploadPage />
                    </>
                  )}
                />
              )}
            />
          }
        /> */}
        <Route
          path={COMPANY_PATHS.createCompany}
          element={
            <AdminRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`企業情報作成 | ${APPNAME}`} />
                      <CreateTenantPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={GENERIC_PATHS.settings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`ユーザ設定 | ${APPNAME}`} />
                  <ViewCurrentUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={GENERIC_PATHS.home}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`HOME | ${APPNAME}`} />
                  <HomeMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.main}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`KAKELY | ${APPNAME}`} />
                      <KakelyMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.allDepartmentSettings}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`部署一覧表示設定 | ${APPNAME}`} />
                      <DepartmentGroupMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.departmentSettings}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`部署一覧表示設定 | ${APPNAME}`} />
                      <EditDepartmentGroupPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.createDepartment}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`部署一覧表示設定 | ${APPNAME}`} />
                      <CreateDepartmentGroupPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.csvUpload}
          element={
            <RegularRoute
              element={() => (
                <ContractRoute
                  appName="KAKELY"
                  element={() => (
                    <PrivateRoute
                      element={() => (
                        <>
                          <PageTitle title={`CSVアップロード | ${APPNAME}`} />
                          <KakelyCsvUploadPage />
                        </>
                      )}
                    />
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.createContact}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`電話帳登録・編集画面 | ${APPNAME}`} />
                      <CreatePhonebookPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={KAKELY_PATHS.showUser}
          element={
            <ContractRoute
              appName="KAKELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`電話帳情報 | ${APPNAME}`} />
                      <ViewPhonebookPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.main}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`ユーザ一覧 | ${APPNAME}`} />
                  <UserMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.showUser}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`ユーザ情報編集| ${APPNAME}`} />
                  <ViewUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.csvUpload}
          element={
            <RegularRoute
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`CSVアップロード | ${APPNAME}`} />
                      <UserCSVUploadPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.photoUpload}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`顔写真アップロード | ${APPNAME}`} />
                  <UserPhotoUploadPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.createUser}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`ユーザ情報参照・編集 | ${APPNAME}`} />
                  <CreateUserPage />
                </>
              )}
            />
          }
        />
        <Route
          path={USER_PATHS.passwordReset}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`パスワード変更 | ${APPNAME}`} />
                  <PasswordResetPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.hub}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`HUB管理 | ${APPNAME}`} />
                  <HubMainPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageMentalCare}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`メンタルヘルスケア管理 | ${APPNAME}`} />
                  <MentalHealthPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageAISettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`メンタルヘルスケア管理 | ${APPNAME}`} />
                  <AISettingsPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.manageSettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`スタンプ管理 | ${APPNAME}`} />
                  <StampSettingsPage />
                </>
              )}
            />
          }
        />
        <Route
          path={WORKJOY_PATHS.biToolSettings}
          element={
            <PrivateRoute
              element={() => (
                <>
                  <PageTitle title={`BIツール連携 | ${APPNAME}`} />
                  <BiToolPage />
                </>
              )}
            />
          }
        />
        <Route
          path={YOBELY_PATHS.main}
          element={
            <ContractRoute
              appName="YOBELY"
              element={() => (
                <PrivateRoute
                  element={() => (
                    <>
                      <PageTitle title={`YOBELY管理 | ${APPNAME}`} />
                      <YobelyMainPage />
                    </>
                  )}
                />
              )}
            />
          }
        />
        <Route
          path="*"
          element={
            <>
              <PageTitle title={`404 - Not Found | ${APPNAME}`} />
              <NotFound />
            </>
          }
        />
      </Routes>
    </DefaultLayout>
  );
}

export default App;
