export const KAKELY_PATHS = {
  main: "/KAKELY",
  createDepartment: "/KAKELY/departments/settings/create/:parentId",
  showUser: "/KAKELY/:id",
  createContact: "/KAKELY/createContact",
  csvUpload: "/KAKELY/csvUpload",
  departmentSettings: "/KAKELY/departments/settings/:departmentId",
  allDepartmentSettings: "/KAKELY/departments/settings",
};

export const WORKJOY_PATHS = {
  hub: "/hub",
  manageMentalCare: "/hub/mentalheatlhCare/manage",
  manageAISettings: "/hub/mentalhealthCare/manage/aiSettings",
  manageSettings: "/hub/mentalhealthCare/settings",
  biToolSettings: "/hub/BITool/settings",
};

export const YOBELY_PATHS = {
  main: "/YOBELY",
};

export const USER_PATHS = {
  main: "/users",
  csvUpload: "/users/csvUpload",
  photoUpload: "/users/photoupload/",
  createUser: "/users/createUser",
  passwordReset: "/user/information/passwordReset",
  showUser: "/users/:id",
};

export const NOTIFICATION_PATHS = {
  main: "/notifications",
  createNotification: "/notifications/create",
  viewNotification: "/notifications/info/:id",
  newNotification: "/notifications/new",
};

export const COMPANY_PATHS = {
  createCompany: "/company/create",
  listCompanies: "/company/manage",
  manageCompany: "/company/:tenantCode/manage",
  showCompany: "/company/:tenantCode/info",
};

export const GENERIC_PATHS = {
  home: "/home",
  settings: "/settings",
};
