import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { SearchBar } from "../../components/SearchBar/SearchBarTemplate";
import { HeaderItems } from "../../common/HeaderItems";
import {
  getFilteredTenantsData,
  getTenantsData,
} from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import FilterModal from "../../components/Modals/FilterSelectModal";
import PaginationControls from "../../services/PaginationControls";
import { GetCurrentDate } from "../../services/Utilities";
import TenantsTables from "../../components/Tables/TenantTable";

const TenantAdminPage: React.FC = () => {
  const [selectedTenants, setSelectedTenants] = useState<number[]>([]);

  const [filterModalOpen, setFilterModalOpen] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState("");

  const [tenantData, setTenantData] = useState<[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [queryString, setQueryString] = useState<string>("");

  const [allSelected, setAllSelected] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentTenants = tenantData.slice(firstIndex, lastIndex);

  const totalPages = Math.ceil(tenantData.length / itemsPerPage);

  const nonSelectedToast = () => toast.error("企業が選択されていません。");
  const multipleUserSelectedToast = () =>
    toast.error("一つの企業を選んでください。");
  const userDeletedToast = (userNum) =>
    toast.success(`${userNum}の企業が削除されました`);
  const errorToast = (message) => toast.error(message);

  const [filters, setFilters] = useState({
    name: "",
    name_kana: "",
    code: "",
    app_name: "",
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  const matchingTenants = (selectedIds: number[]) => {
    return tenantData.filter((tenant) => selectedIds.includes(tenant.id));
  };

  const handleTenantSelect = (tenantId: number, force = false) => {
    setSelectedTenants((prevSelected) => {
      if (force) {
        return [...prevSelected, tenantId];
      } else if (prevSelected.includes(tenantId)) {
        setAllSelected(false);
        return prevSelected.filter((id) => id !== tenantId);
      } else {
        return [...prevSelected, tenantId];
      }
    });
  };

  const handleDeselectAll = () => {
    setAllSelected(false);
    setSelectedTenants([]);
  };

  const handleDownloadCSV = () => {
    const selectedUserData = matchingTenants(selectedTenants);

    const csvContent = convertToCSV(selectedUserData);
    const currentDate = GetCurrentDate();

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `${selectedUserData.length}名分の企業情報_${currentDate}.csv`,
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const convertToCSV = (data): string => {
    if (!data || data.length === 0) return "";

    const excludedFields = [];
    const contractFields = [
      "app_id",
      "app_name",
      "status",
      "contract_count",
      "current_users",
      "start_date",
      "end_date",
    ];

    const headers = [
      ...Object.keys(data[0]).filter(
        (header) => !excludedFields.includes(header) && header !== "contracts",
      ),
      ...contractFields,
    ].join(",");

    const rows = data.map((tenant) => {
      const tenantInfo = Object.entries(tenant)
        .filter(([key]) => !excludedFields.includes(key) && key !== "contracts")
        .map(([_, value]) => `"${value}"`)
        .join(",");

      if (tenant.contracts && tenant.contracts.length > 0) {
        return tenant.contracts
          .map((contract) => {
            const contractInfo = contractFields
              .map((field) => {
                const key =
                  field === "contract_contract_count"
                    ? "contract_count"
                    : field;
                return `"${contract[key] || ""}"`;
              })
              .join(",");
            return `${tenantInfo},${contractInfo}`;
          })
          .join("\n");
      } else {
        return `${tenantInfo},` + `"未契約"`;
      }
    });

    return [headers, ...rows].join("\n");
  };

  useEffect(() => {
    if (!isFiltered && searchTerm == "") {
      getTenantsData(setTenantData, setError, setLoading);
      setCurrentPage(1);
    }
  }, [isFiltered, searchTerm]);

  const onSearch = () => {
    if (searchTerm) {
      const query: string = `search=${searchTerm}`;
      getFilteredTenantsData(setTenantData, setError, setLoading, query);
      setIsFiltered(true);
      setCurrentPage(1);
    } else {
      setIsFiltered(false);
    }
  };

  const onFilteredSearch = () => {
    setFilterModalOpen(true);
  };

  const onFilteredModalClose = () => {
    setFilterModalOpen(false);
  };

  const doFilteredSearch = async (filters) => {
    const query: URLSearchParams = new URLSearchParams();

    Object.keys(filters).forEach((key) => {
      if (filters[key]) {
        let ransackKey;

        switch (key) {
          case "app_name":
            ransackKey = `app_name`;
            break;
          default:
            ransackKey = `q[${key}_cont]`;
            break;
        }
        query.append(ransackKey, filters[key]);
      }
    });

    setQueryString(query.toString());

    if (query.toString()) {
      await getFilteredTenantsData(
        setTenantData,
        setLoading,
        setError,
        query.toString(),
      );
      setIsFiltered(true);
      setCurrentPage(1);
    } else {
      await getTenantsData(setTenantData, setError, setLoading);
    }
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const headerItems: {
    icon: {
      width: number;
      height: number;
      viewBox: string;
      path: string;
    };
    link: string;
    text: string;
    onClick: () => void;
  }[] = [
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1M15 16H21M18 19V13",
      },
      link: "/company/create",
      text: "企業新規追加",
      onClick: () => console.log("tenant create clicked"),
    },
    {
      icon: {
        width: 24,
        height: 24,
        viewBox: "0 0 24 24",
        path: "M17 3.33782C19.989 5.06687 22 8.29859 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 8.29859 4.01099 5.06687 7 3.33782M8 12L12 16M12 16L16 12M12 16V2",
      },
      link: "",
      text: "CSVダウンロード",
      onClick:
        selectedTenants.length === 0 ? nonSelectedToast : handleDownloadCSV,
    },
    // {
    //   icon: {
    //     width: 22,
    //     height: 22,
    //     viewBox: "0 0 22 22",
    //     path: "M6 19.6621C3.01099 17.9331 1 14.7013 1 10.9999C1 5.47709 5.47715 0.999939 11 0.999939C16.5228 0.999939 21 5.47709 21 10.9999C21 14.7014 18.989 17.9331 16 19.6621M15 11L11 7.00001M11 7.00001L7 11M11 7.00001V21",
    //   },
    //   link: "/company/manage/csvUpload",
    //   text: "CSVアップロード",
    //   onClick: () => console.log("CSV Upload clicked"),
    // },
  ];

  return (
    <>
      <div>
        <TitleWithDivider titleText="企業一覧" useDivider={true} />
        <SearchBar
          onSearch={onSearch}
          onFilterSearch={onFilteredSearch}
          searchBarText={"テナント検索キーワード"}
          buttonLeft={"検索"}
          buttonRight={"絞込検索"}
          setSearchTerm={setSearchTerm}
        ></SearchBar>
        <div className="flex justify-between">
          <HeaderItems
            items={headerItems}
            topPadding={5}
            useDivider={false}
          ></HeaderItems>
          <div className="mt-5 flex flex-center justify-end items-center">{`総テナント数: ${tenantData.length}企業`}</div>
        </div>
        <div className="mb-5"></div>
        {loading ? (
          <LoadingIcon />
        ) : (
          <>
            <TenantsTables
              onTenantSelect={handleTenantSelect}
              onDeselectAll={handleDeselectAll}
              tenantInformation={currentTenants}
              allSelected={allSelected}
              loading={loading}
              setAllSelected={setAllSelected}
            />
            <PaginationControls
              currentPage={currentPage}
              totalPages={totalPages}
              totalItems={tenantData.length}
              displayedItem={"企業"}
              onPageChange={handlePageChange}
              setPerPage={setItemsPerPage}
              perPage={itemsPerPage}
            />
          </>
        )}
        <ToastContainer />
        {/* <WarningModal
          title={warningModalTitle}
          body={warningModalBody}
          confirmText={warningModalConfirm}
          isOpen={deleteModalOpen}
          onConfirm={delete}
          onRequestClose={closeDeleteModal}
        ></WarningModal> */}
        <FilterModal
          type={"tenant"}
          isOpen={filterModalOpen}
          onRequestClose={onFilteredModalClose}
          onApplyFilters={doFilteredSearch}
          filters={filters}
        ></FilterModal>
      </div>
    </>
  );
};

export default TenantAdminPage;
