import axios, { AxiosResponse } from "axios";
import Keycloak from "keycloak-js";
import { useSelector } from "react-redux";

// default keycloak values imported from environment
const keycloakDefaults = {
  keycloakUrl: import.meta.env.VITE_KEY_CLOAK_URL,
  keycloakClient: import.meta.env.VITE_KEY_CLOAK_CLIENT,
};

// get the keycloak admin token which is used for various authentication processes
// TODO: remove from plain text exposure.
const getAdminToken = async () => {
  const response: AxiosResponse<any, any> = await axios.post(
    `${keycloakDefaults.keycloakUrl}auth/realms/master/protocol/openid-connect/token`,
    new URLSearchParams({
      client_id: "admin-cli",
      username: "admin",
      password: "1unfdrL#g$h9y2W3-6a0cZyG",
      grant_type: "password",
    }),
  );

  return response.data.access_token;
};

// initial options for the Keycloak instance.
// check Keycloak documentation for available options
export const initOptions = {
  onLoad: "check-sso",
  pkceMethod: "S256",
  checkLoginIframe: false,
};

// UNUSED
// function to check if the user exists in keycloak
// does not work due to CORS
// keeping in case it proves useful in the future as a reference.
export const checkUserExists = async (username, email) => {
  try {
    const token = await getAdminToken();
    const realm: string | null = useSelector((state) => state.auth.realm);

    const emailResponse: AxiosResponse<any, any> = await axios.get(
      `${keycloakDefaults.keycloakUrl}auth/admin/realms/${realm}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          email: email,
        },
      },
    );

    const usernameResponse: AxiosResponse<any, any> = await axios.get(
      `${keycloakDefaults.keycloakUrl}auth/admin/realms/${realm}/users`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          username: username,
        },
      },
    );

    const emailExists: boolean = emailResponse.data.length > 0;
    const usernameExists: boolean = usernameResponse.data.length > 0;

    return { emailExists, usernameExists };
  } catch (error) {
    console.error("Error checking user existence:", error);
    throw error;
  }
};

// check if the realm exists in Keycloak
// used when creating a tenant to see check if the realm exists
// if it does, return OK, else return 404 not found.
export const checkRealmExists = async (realm) => {
  try {
    const response: Response = await fetch(
      `${keycloakDefaults.keycloakUrl}realms/${realm}`,
      {
        method: "GET",
      },
    );

    if (response.ok) {
      return true;
    }

    if (response.status === 404) {
      return false;
    }

    throw new Error("unexpected response status: " + response.status);
  } catch (error) {
    console.error("error checking realm existence:", error);
    return false;
  }
};

// set the Keycloak realm and return the Keycloak object for initialization.
export const SetKeycloakRealm = (realm) => {
  const initOptions = {
    url: keycloakDefaults.keycloakUrl,
    realm: realm,
    clientId: keycloakDefaults.keycloakClient,
  };

  const keycloak = new Keycloak(initOptions);

  return keycloak;
};
