import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import PasswordForm from "../../components/Forms/PasswordForm";
import { XCircleIcon } from "@heroicons/react/16/solid";
import useClickOutside from "./ClickOutsideHandler";
import useDrag from "./DragHandler";
import { GetPasswordPolicy } from "../../services/Utilities";
import { error_message } from "../../constants/Errors";
import messages from "../../constants/Messages";

/**
 * PasswordResetModal - A modal component for resetting a user's password.
 *
 * This component displays a modal dialog that allows users to enter and confirm a new password.
 * It validates the input fields to ensure that both passwords match and are not blank.
 * Upon successful validation, it triggers a callback function to handle the password reset process.
 * Toast notifications are used to inform the user of any errors or successful operations.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.isOpen - A flag indicating whether the modal is open.
 * @param {function} props.onRequestClose - A callback function to close the modal.
 * @param {function} props.onPasswordResetSuccess - A callback function called with the new password when the password reset is successful.
 * @returns {JSX.Element | null} - A JSX element representing the password reset modal if `isOpen` is true, or null if the modal is closed.
 *
 * Usage:
 * <PasswordResetModal
 *   isOpen={isModalOpen}
 *   onRequestClose={closeModalHandler}
 *   onPasswordResetSuccess={handlePasswordResetSuccess}
 * />
 */

const PasswordResetModal = ({
  isOpen,
  onRequestClose,
  onPasswordResetSuccess,
}) => {
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const modalRef = useRef<HTMLDivElement>(null);

  const { position, handleMouseDown } = useDrag(modalRef, isOpen);
  useClickOutside(isOpen, onRequestClose, modalRef);

  const noMatchToast = () => toast.error(error_message.password.no_match);
  const blankFieldToast = () => toast.error(error_message.password.blank_field);
  const nonPolicyToast = () => toast.error(error_message.password.bad_policy);
  const passwordChangedToast = () => toast.success(messages.password.success);

  const passwordKey: string = "password";
  const verifyPasswordKey: string = "verifyPassword";
  const passwordPolicy: string = GetPasswordPolicy();

  const [errors, setErrors] = useState({
    password: false,
    verifyPassword: false,
    noMatch: false,
  });

  // function to verify if any of the fields contain errors
  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const verifyBlankFields = () => {
    return newPassword !== "" && confirmPassword !== "";
  };

  const verifyNewPassword = () => {
    return newPassword === confirmPassword;
  };

  const verifyPasswords = (): boolean => {
    if (!verifyBlankFields()) {
      blankFieldToast();
      return false;
    }
    if (!verifyNewPassword()) {
      noMatchToast();
      return false;
    }

    if (!verifyFields()) {
      nonPolicyToast();
      return false;
    }

    return true;
  };

  const onCancel = () => {
    onRequestClose();
  };

  const onConfirm = async () => {
    if (verifyPasswords()) {
      onPasswordResetSuccess(confirmPassword);
      onRequestClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div
        ref={modalRef}
        onMouseDown={handleMouseDown}
        style={{
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
        className="bg-white border border-text-field-border rounded-lg sm:w-3/4 md:w-1/3 lg:w-1/2"
      >
        <div className="mayo-card-header p-4 flex justify-between items-center">
          <div className="">パスワード変更</div>
          <button onClick={onRequestClose} className="text-xl hover:opacity-50">
            <svg
              className="ml-2 fill-body hover:fill-primary"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <XCircleIcon></XCircleIcon>
            </svg>
          </button>
        </div>

        <div className="ml-5">
          <div className="mb-5 mt-5">
            <PasswordForm
              label={"変更後のパスワード"}
              password={newPassword}
              setPassword={setNewPassword}
              modal={true}
              identifier={passwordKey}
              errors={errors.password}
              setError={updateError}
            />
          </div>

          <div>
            <PasswordForm
              label={"変更後のパスワード（再入力）"}
              password={confirmPassword}
              setPassword={setConfirmPassword}
              modal={true}
              identifier={verifyPasswordKey}
              errors={errors.verifyPassword}
              setError={updateError}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="ml-5 pt-10 text-zinc-400 font-bold">
            {"パスワードポリシー: "}
          </div>
          <div className="ml-5 pt-10 text-zinc-400 font-bold">{`${passwordPolicy}`}</div>
        </div>

        <div className="mb-5">
          <ConfirmCancelButtons
            confirmText={"変更する"}
            onConfirm={onConfirm}
            onCancel={onCancel}
          />
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PasswordResetModal;
