import React, { useState, ReactNode, useEffect } from "react";
import Sidebar from "../components/Sidebar/index";
import {
  DefaultSidebarItems,
  AdminSidebarItems,
} from "../components/Sidebar/SideBarItems";
import { useDispatch, useSelector } from "react-redux";
import { getCompanyContractData } from "../services/API/Requests";
import { useKeycloak } from "@react-keycloak/web";
import {
  addApp,
  setAppsCheckedState,
} from "../services/StateManagement/AppSlice";
import { CONTRACT_STATUS } from "../constants/Enums";

const DefaultLayout: React.FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.status);
  const isSysAdminRole = useSelector((state) => state.auth.isAdmin);
  const companyId = useSelector((state) => state.company.companyId);
  const appsChecked = useSelector((state) => state.apps.appsChecked || false);
  const [availableApps, setAvailableApps] = useState([]);
  const [apps, setApps] = useState([]);

  const { keycloak } = useKeycloak();

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<String>("");

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const isAuthPage = location.pathname === "/signIn/authenticationChallenge";

  useEffect(() => {
    const fetchCompanyContractData = async () => {
      try {
        setLoading(true);
        await getCompanyContractData(
          setAvailableApps,
          setLoading,
          setError,
          companyId,
        ).then(() => {
          if (availableApps && availableApps.length > 0) {
            const validApps = availableApps.filter(
              (app) => app.status === CONTRACT_STATUS.ACTIVE,
            );
            validApps.forEach((app) => {
              dispatch(addApp(app.app_name));
            });
            setApps(validApps);
            setLoading(false);
          }
          dispatch(setAppsCheckedState(true));
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompanyContractData();
  }, [keycloak.authenticated, companyId, dispatch]);

  useEffect(() => {
    if (availableApps && availableApps.length > 0) {
      const validApps = availableApps.filter(
        (app) => app.status === CONTRACT_STATUS.ACTIVE,
      );
      validApps.forEach((app) => {
        dispatch(addApp(app.app_name));
      });
      setApps(validApps);
      setLoading(false);
    }
    dispatch(setAppsCheckedState(true));
    dispatch(addApp("test"));
  }, [availableApps]);

  return (
    <div>
      {/* // <div className={isLoginPage || isAuthPage ? "mayo-gradient-bg" : "bg-mayo-bg"}> */}
      <div className="bg-mayo-bg">
        {/* ===== Page Wrapper Start ===== */}
        <div className="flex h-screen overflow-hidden">
          {/* ===== Sidebar Start ===== */}
          {!isAuthPage && isAuthenticated && (
            <Sidebar
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
              items={
                isSysAdminRole
                  ? AdminSidebarItems(companyId)
                  : DefaultSidebarItems(companyId, apps)
              }
            />
          )}
          {/* ===== Sidebar End ===== */}

          {/* ===== Content Area Start ===== */}
          <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
            <main>
              <div className="mx-auto max-w-screen-3xl p-4 md:p-6 2xl:p-10">
                {children}
              </div>
            </main>
          </div>
          {/* ===== Content Area End ===== */}
        </div>
        {/* ===== Page Wrapper End ===== */}
      </div>
      {/* </div> */}
    </div>
  );
};

export default DefaultLayout;
