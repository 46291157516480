import {
  translateAndReorderHeaders,
  userHeaderMapping,
} from "./TranslateCsvHeaders";

const convertToCSV = (data, headerMapping) => {
  if (!data || data.length === 0) return "";

  const orderedHeaders = translateAndReorderHeaders(
    Object.keys(data[0]),
    headerMapping,
  );
  const headers = orderedHeaders.join(",");

  const rows = data.map((row) =>
    orderedHeaders
      .map((key) => {
        const originalKey = Object.keys(headerMapping).find(
          (k) => headerMapping[k] === key,
        );
        let value;

        if (key === "ログインパスワード") {
          value = "**********";
        } else if (key === "利用アプリ" && row.user_apps) {
          value = row.user_apps.map((app) => app.app_name).join(", ");
        } else {
          value = row[originalKey] || "";
        }

        return `"${value}"`;
      })
      .join(","),
  );

  return [headers, ...rows].join("\n");
};

export default convertToCSV;
