import React, { useEffect, useState } from "react";
import { getAppData } from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import { BlankContract } from "../../dummy/BlankContractData";
import { CONTRACT } from "../../types/contract";
import { error_message } from "../../constants/Errors";
import { CONTRACT_STATUS } from "../../constants/Enums";

/**
 * CreateTenantLicenseTable - A component for displaying and managing tenant licenses and contracts for various apps.
 *
 * This component fetches app and license data and displays them in a table format.
 * Users can view and modify the contract status, contract period, and unit price for each app.
 * The data is fetched on component mount and stored in state. Updates to the contracts can be
 * made via the `updateContractData` function.
 *
 * @param {Object} props - The props object.
 * @param {Object[]} props.contractData - An array of contract objects representing the current contract data.
 * @param {function} props.setContractData - A function to set the contract data state.
 * @param {function} props.updateContractData - A function to update specific fields in the contract data.
 *
 * @returns {JSX.Element} - A JSX element representing the tenant license table.
 *
 * Usage:
 * <CreateTenantLicenseTable
 *   contractData={contractData}
 *   setContractData={setContractData}
 *   updateContractData={updateContractData}
 * />
 */

const DisplayStatus = ({
  apps,
  contract,
  updateContractData,
}: {
  apps: any[];
  contract: CONTRACT;
  updateContractData: Function;
}) => {
  const contractStatusLabels: Record<CONTRACT_STATUS, string> = {
    [CONTRACT_STATUS.ACTIVE]: "契約中",
    [CONTRACT_STATUS.INACTIVE]: "未契約",
    [CONTRACT_STATUS.EXPIRED]: "契約終了",
  };

  const app: any = apps.find((app) => app.id === contract.app_id);
  const appName: any = app
    ? app.name
    : error_message.tenant.unknown_app(contract.app_id);

  return (
    <tr key={contract.app_id}>
      <td
        className="py-4 whitespace-nowrap text-xl font-medium text-gray-900 w-1/5"
        title={app ? app.description : ""}
      >
        {appName}
      </td>
      <td>
        <div className="mb-2 mt-2 text-xl text-black">
          <label className="flex items-center">
            <select
              className="dropdown-bar min-w-30"
              value={contract.status}
              onChange={(e) =>
                updateContractData(contract.app_id, "status", e.target.value)
              }
            >
              {Object.values(CONTRACT_STATUS).map((status) => (
                <option key={status} value={status}>
                  {contractStatusLabels[status]}
                </option>
              ))}
            </select>
          </label>
        </div>
      </td>
      {/* <td> number of users area
        <div className="flex items-center">
          <input className="form-text-field-border border" />
          <span className="mx-2">/</span>
          <input className="form-text-field-border border mr-10" />
        </div>
      </td> */}
      <td>
        <div className="flex items-center">
          <input
            type="date"
            value={contract.start_date?.split("T")[0]}
            onChange={(e) =>
              updateContractData(contract.app_id, "start_date", e.target.value)
            }
            className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
          />
          <span className="mx-2">~</span>
          <input
            type="date"
            value={contract.end_date?.split("T")[0]}
            onChange={(e) =>
              updateContractData(contract.app_id, "end_date", e.target.value)
            }
            className="text-title-md font-bold text-black mb-2 w-auto border border-text-field-border rounded-xl p-2"
          />
        </div>
      </td>
      <td>
        <div className="ml-5 flex items-center">
          <input
            className="form-text-field-border min-w-30 border"
            type="number"
            value={contract.contract_count}
            min="0"
            onChange={(e) =>
              updateContractData(
                contract.app_id,
                "contract_count",
                e.target.value,
              )
            }
          />
        </div>
      </td>
      <td>
        <div className="ml-5 flex items-center">
          <input
            className="form-text-field-border border"
            type="number"
            value={contract.unit_price}
            min="0"
            onChange={(e) =>
              updateContractData(contract.app_id, "unit_price", e.target.value)
            }
          />
          <span className="ml-5 text-xl">円</span>
        </div>
      </td>
    </tr>
  );
};

const CreateTenantLicenseTable = ({
  contractData,
  setContractData,
  updateContractData,
}) => {
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const [appData, setAppData] = useState<any[]>([]);

  useEffect(() => {
    const fetchAppData = async () => {
      setLoading(true);
      await getAppData(setAppData, setError, setLoading);
      setLoading(false);
    };
    fetchAppData();
  }, []);

  useEffect(() => {
    const createBlankContracts = (appData) => {
      return appData.map((app) => ({
        ...BlankContract,
        app_id: app.id,
      }));
    };

    const blankContracts = createBlankContracts(appData);
    if (contractData.length <= 0) {
      setContractData(blankContracts);
    }
  }, [appData]);

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <LoadingIcon />
      ) : error ? (
        <p className="text-red-500">Error: {error}</p>
      ) : appData.length > 1 ? (
        <table className="min-w-full divide-y divide-mayo-divider-dark table-fixed">
          <thead>
            <tr>
              <th
                scope="col"
                className="pb-2 pt-3 text-left text-lg font-medium text-font-label-blue uppercase min-w-[150px]"
              >
                アプリ
              </th>
              <th
                scope="col"
                className="pb-2 pt-3 text-left text-lg font-medium text-font-label-blue uppercase min-w-[160px]"
              >
                契約状態
              </th>
              <th
                scope="col"
                className="pb-2 pt-3 text-left text-lg font-medium text-font-label-blue uppercase min-w-[150px]"
              >
                契約期間
              </th>
              <th
                scope="col"
                className="pl-3 pb-2 pt-3 text-left text-lg font-medium text-font-label-blue uppercase min-w-[160px]"
              >
                ライセンス数
              </th>
              <th
                scope="col"
                className="pl-3 pb-2 pt-3 text-left text-lg font-medium text-font-label-blue uppercase min-w-[250px]"
              >
                単価
              </th>
            </tr>
          </thead>
          <tbody>
            {contractData.map((contract) => (
              <DisplayStatus
                apps={appData}
                contract={contract}
                key={contract.app_id}
                updateContractData={updateContractData}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <h1>{error_message.tenant.no_contract}</h1>
      )}
    </div>
  );
};

export default CreateTenantLicenseTable;
