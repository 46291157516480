import React from "react";

interface LoadingIconProps {
  width?: number;
  height?: number;
  textSize?: string;
}

const LoadingIcon: React.FC<LoadingIconProps> = ({
  width = 15,
  height = 15,
  textSize = "text-xl",
}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div
        style={{ width: `${width}px`, height: `${height}px` }}
        className="border-4 border-t-4 border-white border-t-blue-500 rounded-full animate-spin"
      ></div>
      <div className={`font-bold ${textSize} mt-4`}>読み込み中</div>
    </div>
  );
};

export default LoadingIcon;
