import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { USER } from "../../types/user";
import LoadingIcon from "../../common/LoadingIcon";
import { error_message } from "../../constants/Errors";

/**
 * ContactsTable - A component for displaying a paginated list of user contact information.
 *
 * This component displays a table of users with their business card images, name, company,
 * position, phone number, and email address. It supports search functionality,
 * selection of individual users, and a "select all" option. The component also includes
 * pagination and image loading status management.
 *
 * @param {Object} props - The props object.
 * @param {function} props.onUserSelect - A function called when a user is selected or deselected.
 * @param {function} props.onDeselectAll - A function called when all users are deselected.
 * @param {USER[]} props.userInformation - An array of user objects to be displayed in the table.
 * @param {string} props.searchTerm - A search term used to filter users based on their information.
 *
 * @returns {JSX.Element} - A JSX element representing the contacts table.
 *
 * Usage:
 * <ContactsTable
 *   onUserSelect={handleUserSelect}
 *   onDeselectAll={handleDeselectAll}
 *   userInformation={userList}
 *   searchTerm={searchTerm}
 * />
 */

const ContactsTable = ({
  onUserSelect,
  onDeselectAll,
  userInformation,
  allSelected,
  setAllSelected,
  users,
}) => {
  const [userData, setUserData] = useState<USER[]>(userInformation);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState(false);

  const [imageLoading, setImageLoading] = useState(true);

  useEffect(() => {
    setUserData(userInformation);
  }, [userInformation]);

  useEffect(() => {
    setSelectedUsers(users);
  }, [users]);

  useEffect(() => {
    setSelectAll(allSelected);
  }, [allSelected]);

  const handleUserSelect = (userId) => {
    setSelectedUsers((prevSelected) => {
      if (prevSelected.includes(userId)) {
        return prevSelected.filter((id) => id !== userId);
      } else {
        return [...prevSelected, userId];
      }
    });
    onUserSelect(userId);
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setAllSelected(!selectAll);
    if (!selectAll) {
      const allUserIds = currentUsers.map((user) => user.id);
      allUserIds.forEach((userId) => onUserSelect(userId, true));
      setSelectedUsers(allUserIds);
    } else {
      setSelectedUsers([]);
      onDeselectAll();
    }
  };

  //　ユーザページ logic
  const itemsPerPage: number = 20; // 1ページあたりのuserの最大数
  const lastUserIndex: number = currentPage * itemsPerPage; // 最終のuserのインデックス
  const firstUserIndex: number = lastUserIndex - itemsPerPage; // 最初のuserのインデックス
  const currentUsers: USER[] = userData.slice(firstUserIndex, lastUserIndex); // 現在表示されているusers

  const totalPages: number = Math.ceil(userData.length / itemsPerPage);

  return (
    <div className="w-full">
      <div className="border border-text-field-border rounded-2xl overflow-hidden">
        <div className="overflow-x-auto">
          <table className="min-w-full table-fixed mayo-card-header rounded-2xl">
            <thead className="border-b border-text-field-border">
              <tr>
                <th className="w-12 p-4">
                  <input
                    type="checkbox"
                    className="w-4 h-4"
                    checked={selectAll}
                    onLoad={() => setImageLoading(false)}
                    onChange={handleSelectAll}
                  />
                </th>
                <th className="min-w-[220px] md:w-40 py-5 text-left label-light-blue-xl">
                  名刺画像
                </th>
                <th className="min-w-[150px] md:w-56 py-5 pl-2 text-left text-xl label-light-blue-xl">
                  氏名・企業
                </th>
                <th className="min-w-[150px] md:w-56 py-5 text-left text-xl label-light-blue-xl">
                  部署・役職
                </th>
                <th className="min-w-[150px] md:w-56 py-5 text-left text-xl label-light-blue-xl">
                  電話番号
                </th>
                <th className="min-w-[200px] lg:w-80 py-5 text-left text-xl label-light-blue-xl">
                  メールアドレス
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-text-field-border rounded-2xl">
              {currentUsers.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center py-4">
                    {error_message.user.not_loaded}
                  </td>
                </tr>
              ) : (
                currentUsers.map((user) => (
                  <tr key={user.id}>
                    <td className="w-12 p-4 text-center">
                      <input
                        type="checkbox"
                        className="w-4 h-4"
                        checked={selectedUsers.includes(user.id)}
                        onChange={() => handleUserSelect(user.id)}
                      />
                    </td>
                    <td className="max-w-[160px] md:w-50 py-5">
                      {user.card_image_urls.length >= 1 ? (
                        <div className="relative w-full h-30">
                          {imageLoading && (
                            <div className="absolute inset-0 flex items-center justify-center">
                              <LoadingIcon />
                            </div>
                          )}
                          <img
                            src={user.card_image_urls[0].url}
                            onLoad={() => setImageLoading(false)}
                            alt="名刺画像"
                            className="w-full h-full object-cover rounded-md"
                          />
                        </div>
                      ) : (
                        <div className="w-full h-30 flex items-center justify-center rounded-md text-center font-bold text-xl bg-slate-100">
                          <p className="text-center">未登録</p>
                        </div>
                      )}
                    </td>
                    <td className="min-w-[150px] md:w-56 pl-2">
                      <div className="text-md font-medium text-gray-900 pt-4">
                        {user.company_name}
                      </div>
                      <Link to={`/KAKELY/${user.id}`}>
                        <div className="text-2xl font-bold text-black hover:opacity-50">
                          {user.last_name} {user.first_name}
                        </div>
                      </Link>
                      <div className="whitespace-nowrap text-md font-medium text-gray-900 mb-6">
                        {user.last_kana_name} {user.first_kana_name}
                      </div>
                    </td>
                    <td className="min-w-[150px] md:w-40 px-2 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                      {user.position_name}
                    </td>
                    <td className="min-w-[150px] md:w-40 px-2 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                      {user.personal_tel}
                    </td>
                    <td className="min-w-[200px] lg:w-80 px-2 py-4 whitespace-nowrap text-xl font-medium text-gray-900">
                      {user.email}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ContactsTable;
