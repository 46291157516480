import { maxStringLength } from "../services/ErrorMessages";

export const error_message = {
  csv: {
    no_data: "CSVデータがありません。",
    parse_error: "CSVファイルの解析中にエラーが発生しました。",
    loading: "ファイルの読み込み中にエラーが発生しました。",
  },
  image: {
    upload: "イメージのアップロードにエラーが発生しました。",
    not_selected: "ファイルが選択されていません",
    no_user: "ユーザデータがありません。アップロードできません。",
    delete: "failed to delete image",
  },
  notification: {
    is_null: "notification is null.",
    start_data: "開始日が終了日より後になることはできません。",
    not_found: (id) => `お知らせ: ${id}の配信が停止されています。`,
    no_data: "お知らせはありません。",
  },
  tenant: {
    date_not_defined: "開始、または終了日日は空であってはいけません。",
    bad_input: "開始日と終了日は正しく設定してください。",
    blank_unit_price: "単価は空であってはいけません。",
    bad_unit_price: "単価は数字のみ使用できます。",
    exists: (name) => `テナントID: ${name}がすでに存在しています。`,
    unknown_app: (id) => `不明なアプリ - ${id}`,
    no_contract: "契約データがありません。",
    no_data: "企業情報を読み取れませんでした",
    realm_not_found: (selectedRealm) =>
      `テナントID<${selectedRealm}>が存在していません。`,
  },
  departments: {
    load_failed: "failed to load departments",
    parent_failed: "failed to update parent:",
    no_data: "部署選択がありません",
    not_found: (id) => `department with id ${id} not found.`,
    get: "部署の情報を取得できませんでした。",
  },
  admin_user: {
    not_defined: "admin user data is not defined.",
  },
  contact: {
    data_load: "Error fetching contact data",
    not_found: (id) => `電話帳: ${id}のデータが見つかりませんでした`,
    card_id_not_found: "Blob ID not found for the selected image.",
  },
  user: {
    not_selected: "ユーザが選択されていません。",
    select_single: "一つのユーザを選んでください。",
    not_found: "ユーザのデータが見つかりませんでした",
    not_found_id: (id) => `ユーザ: ${id}のデータが見つかりませんでした`,
    delete: "Error deleting user:",
    role: "有効なロールを選択してください。",
    no_apps: "利用可能アプリはありません",
    no_app_registered: "アプリはありません",
    not_loaded: "ユーザー情報を読み取れませんでした",
    no_data: "ユーザー情報を読み取れませんでした",
  },
  keycloak: {
    token_refresh: "failed to refresh token",
    token_expired: "token has expired",
  },
  workjoy: {
    no_stamp_data: "スタンプが登録されていません。",
  },
  yobely: {
    search: "検索に失敗しました。",
    not_found: "グループはありません。",
  },
  password: {
    no_match: "パスワードが一致しません。",
    blank_field: "未入力のフィールドがあります。",
    must_init: "パスワードを初期化してください",
    bad_policy: "パスワードがポリシーに一致しません。",
  },
  generic: {
    unknown: "An unknown error has occurred.",
    bad_field: "1つ以上のフィールドに無効な入力があります",
    no_tenant: "テナントが見つかれませんでした。",
    session_expired: "セッション期限が経ちました。",
    no_connection: "管理システムに接続できません。",
    wait_retry: "しばらくお待ちいただき再度ログインをお願いいたします。",
    admin_route: "このページにアクセスする権利はありません。",
    app_not_available: (appName) => `${appName}は現在ご利用いただけません。`,
    no_access: "このページにアクセスすることができません。",
    no_func_bound: "nothing bound.",
  },
};

// map of user field (form) error messages
export const UserFieldErrorMessages = {
  nameError: "有効な名前を入力してください。",
  nameKanaError: "有効なかな文字を入力してください。",
  nameHiraganaError: "有効なかな文字（ひらがな）を入力してください。",
  nameFuriganaError: "有効なフリガナ文字を入力してください。",
  telephoneNumberError:
    "有効な電話番号形式(10, 11数字のみ)で入力してください。",
  loginIdError: "ログインが保存後変更できません。",
  loginIdExistsError: "ログインIDがすでに存在しています。",
  emailExistsError: "メールアドレスがすでに存在しています。",
  employeeNumberError: "有効な数字を入力してください。",
  positionError: "有効な役割を入力してください。",
  lengthError: `${maxStringLength}字以内で入力してください。`,
  roleError: "有効なロールを選択してください。",
  passwordError: "パスワードがポリシーに一致しません。",
  passwordNoMatchError: "パスワードが一致しません。",
};

// map of tenant field (form) error messages
export const TenantFieldErrorMessages = {
  nameError: "有効な名前を入力してください。",
  nameKanaError: "有効なかな文字を入力してください。",
  nameHiraganaError: "有効なかな文字（ひらがな）を入力してください。",
  nameFuriganaError: "有効なフリガナ文字を入力してください。",
  telephoneNumberError: "有効な電話番号形式(10,11数字のみ)で入力してください。",
  loginIdError: "ログインが保存後変更できません。",
  postcodeError: "有効な郵便番号形式(123-1234)で入力してください。",
  positionError: "有効な役割を入力してください。",
  lengthError: `${maxStringLength}字以内で入力してください。`,
  codeError:
    "値は大文字の英字 (A-Z)、数字 (0-9)、アンダースコア (_) のみを含み、5文字以上64文字以内である必要があります。",
};

// map of contract field (form) error messages
export const ContractFieldErrorMessages = {
  dateError: "開始日が終了日より後になることはできません。",
};
