import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteTenantDepartmentData,
  getTenantDepartmentData,
  putTenantDepartmentsData,
} from "../../services/API/Requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingIcon from "../../common/LoadingIcon";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import WarningModal from "../../components/Modals/WarningModal";
import { DEPARTMENT } from "../../types/department";
import {
  handleKanaChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages";
import { error_message, UserFieldErrorMessages } from "../../constants/Errors";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";

/**
 * EditDepartmentGroupPage - A component for editing and deleting a department in the tenant's organizational structure.
 *
 * This component fetches the department data based on the group ID provided in the URL parameters and displays a form for editing the department details.
 * It includes functionality to update the department information, validate the inputs, and delete the department with confirmation.
 *
 * Props:
 * - `groupId` (string): The ID of the department to be edited, extracted from the URL parameters.
 *
 * State:
 * - `department` (DEPARTMENT | null): The current department data being edited.
 * - `isLoading` (boolean): Indicates whether the data is being loaded.
 * - `error` (string): Holds any error messages encountered during data fetching or submission.
 * - `deleteModalOpen` (boolean): Tracks whether the delete confirmation modal is open.
 * - `errors` (object): Tracks validation errors for specific fields (name and name_kana).
 *
 * Functions:
 * - `verifyFields`: Checks if all required fields are valid.
 * - `updateError`: Updates the error state for a specific field.
 * - `handleInputChange`: Updates the department state with the new input values.
 * - `handleSave`: Handles the save action to update the department data via an API call.
 * - `onCancel`: Cancels the edit and navigates back to the department settings page.
 * - `handleDelete`: Deletes the department along with its child departments after user confirmation.
 * - `handleOpenDeleteModal`: Opens the delete confirmation modal.
 * - `handleCloseDeleteModal`: Closes the delete confirmation modal.
 *
 * API Interactions:
 * - `getTenantDepartmentData`: Fetches the department data from the backend API based on the group ID.
 * - `putTenantDepartmentsData`: Updates the department data on the backend API.
 * - `deleteTenantDepartmentData`: Deletes the department data on the backend API.
 *
 * Validation:
 * - `verifyFields`: Ensures all required fields are filled and valid.
 * - `handleKanaChange`: Validates and updates the Kana input field.
 *
 * Toast Notifications:
 * - `errorToast`: Displays an error toast with the provided message.
 * - `successToast`: Displays a success toast with the provided message.
 * - `updateErrorToast`: Displays a specific error toast when an error occurs during data fetching or updates.
 *
 * Modals:
 * - `WarningModal`: A modal that appears for confirming the deletion of the department, informing the user that all child departments will also be deleted.
 *
 * Components:
 * - `LoadingIcon`: A component for displaying a loading spinner when data is being fetched.
 * - `TitleWithDivider`: A component for displaying the title with an optional divider line.
 * - `ConfirmCancelButtons`: A component with confirm and cancel buttons for form submission and cancellation.
 *
 * Navigation:
 * - `navigate`: The hook function used to navigate between routes for saving and deleting departments.
 *
 * Usage:
 * This component is used for managing the details of a specific department in a tenant's organizational structure.
 * It allows users to edit department information, validate inputs, and delete departments along with all their child departments.
 *
 * Example Usage:
 * <EditDepartmentGroupPage />
 */

const EditDepartmentGroupPage: React.FC = () => {
  const { departmentId } = useParams<{ departmentId: string }>();
  const navigate = useNavigate();

  const [department, setDepartment] = useState<DEPARTMENT | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const errorToast = (response) => toast.error(`error:  ${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const updateErrorToast = (errorMsg: string) => toast.error(`${errorMsg}`);

  const warningModalTitle: string = "部署を削除";
  const warningModalBody: string =
    "部署を削除します。部署を削除します。 この操作により、関連するすべての子部署も削除されます。よろしいですか？";
  const warningModalConfirm: string = "削除";

  const [errors, setErrors] = useState({
    name: false,
    name_kana: false,
  });

  useEffect(() => {
    const fetchDepartmentData = async () => {
      try {
        setIsLoading(false);
        await getTenantDepartmentData(
          setDepartment,
          setIsLoading,
          setError,
          departmentId,
        );
      } catch (err) {
        setError(error_message.departments.get);
        updateErrorToast(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDepartmentData();
  }, [departmentId]);

  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleInputChange = (key: string, value: string) => {
    setDepartment((prevState) =>
      prevState ? { ...prevState, [key]: value } : prevState,
    );
  };

  const handleSave = async (event) => {
    event.preventDefault();

    const fields: boolean = verifyFields();

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    if (department) {
      let response;
      await putTenantDepartmentsData(department, setIsLoading, setError)
        .then((response) => {
          if (response.status == 200) {
            successToast(response.message);
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
    }
  };

  const onCancel = () => {
    handleCloseDeleteModal();
    navigate(KAKELY_PATHS.allDepartmentSettings);
  };

  const handleDelete = async (event) => {
    event.preventDefault();

    await deleteTenantDepartmentData(departmentId, setIsLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
        } else {
          errorToast(response);
        }
      })
      .catch((error) => {
        setError(error);
        errorToast(error);
      });

    onCancel();

    setTimeout(() => {
      navigate(KAKELY_PATHS.allDepartmentSettings);
    }, 500);
  };

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
  };

  if (isLoading || !department) {
    return <LoadingIcon />;
  }

  return (
    <div className="container mx-auto p-4">
      <TitleWithDivider titleText={`部署情報更新`} useDivider={true} />
      <form
        className="mayo-card-body border border-text-field-border p-4"
        onSubmit={handleSave}
      >
        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署コード <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department.display_order || ""}
            required
            onChange={(e) => handleInputChange("display_order", e.target.value)}
          />
        </div>

        <div className="divider"></div>

        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署名 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department.name}
            required
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </div>

        <div className="divider"></div>

        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署名(カナ)
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department.name_kana}
            onChange={(e) =>
              handleKanaChange(
                "name_kana",
                e.target.value,
                handleInputChange,
                updateError,
              )
            }
          />
          {errors.name_kana && (
            <p className="text-red-500">
              {UserFieldErrorMessages.nameKanaError}
            </p>
          )}
        </div>
        <div className="flex justify-end items-center">
          <button
            type="button"
            className="button-confirm max-h-10 mt-10"
            onClick={handleOpenDeleteModal}
          >
            削除
          </button>
          <ConfirmCancelButtons
            confirmText={"更新"}
            onCancel={onCancel}
            form={true}
          ></ConfirmCancelButtons>
        </div>
      </form>

      <WarningModal
        title={warningModalTitle}
        body={warningModalBody}
        confirmText={warningModalConfirm}
        isOpen={deleteModalOpen}
        onConfirm={handleDelete}
        onRequestClose={handleCloseDeleteModal}
      ></WarningModal>
      <ToastContainer />
    </div>
  );
};

export default EditDepartmentGroupPage;
