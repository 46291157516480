import React from "react";

import PhonebookProfileForm from "../../components/Forms/PhonebookProfileForm.tsx";
import { TitleWithDivider } from "../../common/TitleWithDivider.tsx";

const CreateContactPage: React.FC = () => {
  return (
    <>
      <div>
        <TitleWithDivider titleText="電話帳登録・編集画面" useDivider={true} />
        <PhonebookProfileForm></PhonebookProfileForm>
      </div>
    </>
  );
};

export default CreateContactPage;
