import { CONTRACT_STATUS } from "../constants/Enums";
import { CONTRACT } from "../types/contract";

export const BlankContract: CONTRACT = {
  tenant_id: 0,
  app_id: 0,
  status: CONTRACT_STATUS.INACTIVE,
  contract_count: 0,
  unit_price: 0,
  start_date: "",
  end_date: "",
};
