import React, { useEffect, useState } from "react";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/24/outline";
import {
  getTenantDepartmentsData,
  putTenantDepartmentsData,
} from "../../services/API/Requests";
import LoadingIcon from "../../common/LoadingIcon";
import { TitleWithDivider } from "../../common/TitleWithDivider";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { HeaderItems } from "../../common/HeaderItems";
import { DEPARTMENT } from "../../types/department";
import ChangeDepartmentParentModal from "../../components/Modals/ChangeDepartmentParentModal";
import { error_message } from "../../constants/Errors";

/**
 * DepartmentGroupMainPage - A component for displaying and managing the hierarchical structure of tenant departments.
 *
 * This component fetches and displays tenant departments in a nested tree view format, allowing users to
 * create, edit, and view the structure of the departments. It also includes toast notifications for success
 * and error feedback.
 *
 * State:
 * - `originalTenantDepartments` (DEPARTMENT[]): Stores the original, unfiltered list of tenant departments.
 * - `tenantDepartments` (DEPARTMENT[]): Stores the current list of tenant departments being displayed.
 * - `selectedDisplayGroup` (DEPARTMENT | null): Tracks the currently selected group for display.
 * - `expandedNodes` (object): Tracks the expanded state of each department node in the tree view.
 * - `error` (string): Holds any error messages encountered during data fetching.
 * - `isLoading` (boolean): Indicates whether the data is being loaded.
 * - `originalGroupName` (string | null): Stores the name of the original group for editing purposes.
 *
 * Functions:
 * - `toggleExpand`: Toggles the expanded state of a department node.
 * - `fetchData`: Fetches the department data from the backend API.
 * - `buildTenantTree`: Builds a hierarchical tree structure from the flat department data.
 * - `handleOpenEditPage`: Navigates to the department edit page based on the selected department ID.
 * - `handleOpenCreatePage`: Navigates to the department creation page for the selected department.
 *
 * Subcomponents:
 * - `DepartmentTree`: A recursive component for rendering the department tree view.
 *   - `displayTree`: Recursively renders the nested list of departments.
 *
 * Toast Notifications:
 * - `updateSuccesfulToast`: Displays a success toast when departments are updated successfully.
 * - `updateErrorToast`: Displays an error toast when an error occurs during data fetching or updates.
 *
 * API Interactions:
 * - `getTenantDepartmentsData`: Fetches the tenant departments data from the backend API.
 *
 * Navigation:
 * - `navigate`: The hook function used to navigate between routes for editing and creating departments.
 *
 * Components:
 * - `TitleWithDivider`: A component for displaying the title with an optional divider line.
 * - `HeaderItems`: A component that renders header items with icons and actions.
 * - `LoadingIcon`: A component for displaying a loading spinner.
 * - `ToastContainer`: A component from react-toastify used to display toast notifications.
 *
 * Usage:
 * This component is used for managing and displaying the hierarchical structure of tenant departments.
 * It allows users to create new departments, edit existing ones, and view the organizational hierarchy in a tree view format.
 *
 * Example Usage:
 * <DepartmentGroupMainPage />
 */

const DepartmentGroupMainPage: React.FC = () => {
  const navigate = useNavigate();
  const [originalTenantDepartments, setOriginalTenantDepartments] = useState<
    DEPARTMENT[]
  >([]);
  const [tenantDepartments, setTenantDepartments] = useState<DEPARTMENT[]>([]);
  const [selectedDisplayGroup, setSelectedDisplayGroup] =
    useState<DEPARTMENT | null>(null);
  const [expandedNodes, setExpandedNodes] = useState<{
    [key: string]: boolean;
  }>({});

  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [parentModalOpen, setParentModalOpen] = useState<boolean>(false);

  const [selectedNodeId, setSelectedNodeId] = useState<number>(0);

  const updateErrorToast = () => toast.error(`${error}`);

  const toggleExpand = (id) => {
    setExpandedNodes((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await getTenantDepartmentsData(
        setTenantDepartments,
        setOriginalTenantDepartments,
        setError,
        setIsLoading,
      );
    } catch (err) {
      setError(error_message.departments.load_failed);
      updateErrorToast;
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const buildTenantTree: (departments: any) => never[] = (departments) => {
    const sortedDepartments: any = departments.sort((a, b) =>
      a.display_order.localeCompare(b.display_order),
    );

    const map = {};
    const roots = [];

    sortedDepartments.forEach((dept) => {
      map[dept.id] = { ...dept, children: [] };
    });

    sortedDepartments.forEach((dept) => {
      if (dept.parent === null) {
        roots.push(map[dept.id]);
      } else {
        if (map[dept.parent]) {
          map[dept.parent].children.push(map[dept.id]);
        }
      }
    });

    const sortChildren = (nodes) => {
      nodes.forEach((node) => {
        if (node.children.length > 0) {
          node.children.sort((a, b) =>
            a.display_order.localeCompare(b.display_order),
          );
          sortChildren(node.children);
        }
      });
    };

    sortChildren(roots);

    return roots;
  };

  const handleOpenEditPage = (index) => {
    navigate(`/KAKELY/departments/settings/${index}`);
  };

  const handleOpenCreatePage = (index) => {
    navigate(`/KAKELY/departments/settings/create/${index}`);
  };

  const handleOpenChangeParentModal = (nodeId) => {
    setSelectedNodeId(nodeId);
    setParentModalOpen(true);
  };

  const handleCloseParentModal = () => {
    setParentModalOpen(false);
  };

  const handleConfirmParentModal = (id, newParent) => {
    setParentModalOpen(false);

    const departmentIndex: number = tenantDepartments.findIndex(
      (dept) => dept.id === id,
    );

    if (departmentIndex !== -1) {
      const updatedDepartments = [...tenantDepartments];

      updatedDepartments[departmentIndex] = {
        ...updatedDepartments[departmentIndex],
        parent: newParent,
      };

      setTenantDepartments(updatedDepartments);

      putTenantDepartmentsData(
        updatedDepartments[departmentIndex],
        setIsLoading,
        setError,
      )
        .then(() => {
          fetchData();
        })
        .catch((err) => {
          console.error(error_message.departments.parent_failed, err);
        });
    } else {
      console.error(error_message.departments.not_found);
    }
  };

  const DepartmentTree = ({ depts }) => {
    const displayTree = (nodes) => {
      return (
        <ul>
          {nodes.map((node) => (
            <li key={node.id} className="node-item">
              <div className="flex justify-between items-center">
                {node.children.length !== 0 ? (
                  <div
                    className="flex justify-normal items-center"
                    onClick={() => toggleExpand(node.id)}
                  >
                    {expandedNodes[node.id] ? (
                      <MinusCircleIcon className="mr-5 w-5 h-5 text-mayo-link-text hover:opacity-50" />
                    ) : (
                      <PlusCircleIcon className="mr-5 w-5 h-5 text-mayo-link-text hover:opacity-50" />
                    )}
                    <div className="underline hover:opacity-50 cursor-pointer label-light-blue-xl">
                      [{node.display_order}] {node.name}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-normal items-center">
                    <div className="label-light-blue-xl">
                      [{node.display_order}] {node.name}
                    </div>
                  </div>
                )}

                <div>
                  {node.parent != null && (
                    <button
                      className="button-small-white ml-2 items-end"
                      onClick={() => handleOpenChangeParentModal(node.id)}
                    >
                      親部署を変更
                    </button>
                  )}
                  <button
                    className="button-small-white ml-2 items-end"
                    onClick={() => handleOpenCreatePage(node.id)}
                  >
                    階層を追加
                  </button>

                  <button
                    className="button-confirm items-end font-normal"
                    onClick={() => handleOpenEditPage(node.id)}
                  >
                    編集
                  </button>
                </div>
              </div>
              <div className="divider mt-2"></div>
              {node.children &&
                node.children.length > 0 &&
                expandedNodes[node.id] && (
                  <div className="ml-10 mt-2">{displayTree(node.children)}</div>
                )}
            </li>
          ))}
        </ul>
      );
    };

    const treeData = buildTenantTree(depts);

    return <div>{displayTree(treeData)}</div>;
  };

  const headerItems = [
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M18 19V13M15 16H21M11 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5C17 6.67869 15.9659 8.11585 14.5 8.70924M12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z",
      },
      text: " マスター作成",
      onClick: () => handleOpenCreatePage("Master"),
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <TitleWithDivider titleText={"部署一覧"} useDivider={true} />
      <HeaderItems items={headerItems} topPadding={5}></HeaderItems>
      <div className="mt-5 mayo-card-body border border-text-field-border p-4">
        <div className="mb-4">
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div className="flex justify-between">
              <div className="w-full pr-4">
                <div className="mayo-card-body overflow-y-auto max-h-[60vh] p-4">
                  {tenantDepartments.length >= 1 ? (
                    <DepartmentTree
                      depts={originalTenantDepartments}
                      expandedNodes={expandedNodes}
                      toggleExpand={toggleExpand}
                    />
                  ) : (
                    <>
                      <h1 className="text-xl font-bold flex justify-center items-center">
                        部署はありません。
                      </h1>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <ChangeDepartmentParentModal
        isOpen={parentModalOpen}
        nodeId={selectedNodeId}
        onRequestClose={handleCloseParentModal}
        onSelectGroup={handleConfirmParentModal}
      ></ChangeDepartmentParentModal>
      <ToastContainer></ToastContainer>
    </div>
  );
};

export default DepartmentGroupMainPage;
