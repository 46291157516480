import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EditiableNotificationCard from "../../components/Cards/Notification/EditNotificationCard";
import ViewNotificationCard from "../../components/Cards/Notification/ViewNotificationCard";
import PauseModal from "../../components/Modals/WarningModal";
import StartModal from "../../components/Modals/WarningModal";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import {
  getNotificationData,
  putNotificationData,
} from "../../services/API/Requests";
import { NOTIFICATION } from "../../types/notification";
import LoadingIcon from "../../common/LoadingIcon";
import { TranslateErrorMessage } from "../../services/ErrorMessages";
import { useSelector } from "react-redux";
import messages from "../../constants/Messages";
import { error_message } from "../../constants/Errors";
import { NOTIFICATION_PATHS } from "../../constants/NavigationPaths";

/**
 * ViewNotificationPage - A component for viewing, editing, and managing the status of a notification.
 *
 * This component fetches and displays the details of a specific notification based on the ID from the URL parameters.
 * It allows users to switch between viewing and editing modes, update the notification details, and start or stop the notification broadcasting.
 *
 * Props:
 * - `id` (string): The ID of the notification to be viewed or edited, extracted from the URL parameters.
 *
 * State:
 * - `loading` (boolean): Indicates whether the notification data is being fetched.
 * - `error` (string): Holds any error messages encountered during data fetching or updating.
 * - `notification` (NOTIFICATION | undefined): The current notification data being viewed or edited.
 * - `pauseModalOpen` (boolean): Tracks whether the pause confirmation modal is open.
 * - `startModalOpen` (boolean): Tracks whether the start confirmation modal is open.
 * - `status` (boolean): Tracks the broadcast status of the notification.
 * - `isEditing` (boolean): Tracks whether the component is in editing mode.
 *
 * Functions:
 * - `broadcastToast`: Displays a success toast for resuming the broadcast.
 * - `stopToast`: Displays a success toast for stopping the broadcast.
 * - `handleStopClick`: Opens the pause confirmation modal.
 * - `handleStopCloseModal`: Closes the pause confirmation modal.
 * - `handleStopConfirm`: Confirms the stop action, updates the status, and displays a success toast.
 * - `handleCloseStartModal`: Closes the start confirmation modal.
 * - `handleStartClick`: Opens the start confirmation modal.
 * - `handleStartConfirm`: Confirms the start action, updates the status, and displays a success toast.
 * - `toggleEditMode`: Toggles between editing and viewing modes.
 * - `handleCancelButtonClick`: Cancels editing and navigates back to the notifications list or switches to viewing mode.
 * - `validateDates`: Validates that the start date is not after the end date.
 * - `handleUpdateButtonClick`: Handles the update action for the notification details.
 *
 * API Interactions:
 * - `getNotificationData`: Fetches the notification data from the backend API based on the notification ID.
 * - `putNotificationData`: Updates the notification data on the backend API.
 *
 * Toast Notifications:
 * - `broadcastToast`: Displays a success toast for resuming the broadcast.
 * - `stopToast`: Displays a success toast for stopping the broadcast.
 * - `errorToast`: Displays an error toast with the provided message.
 * - `successToast`: Displays a success toast with the provided message.
 *
 * Modals:
 * - `PauseModal`: A modal for confirming the pause (stop) action for the notification broadcast.
 * - `StartModal`: A modal for confirming the start (resume) action for the notification broadcast.
 *
 * Components:
 * - `EditiableNotificationCard`: A component for displaying the editable notification details in a form.
 * - `ViewNotificationCard`: A component for displaying the notification details in a non-editable view.
 * - `LoadingIcon`: A component for displaying a loading spinner when data is being fetched.
 * - `TitleWithDivider`: A component for displaying the title with an optional divider line.
 *
 * Navigation:
 * - `navigate`: The hook function used to navigate between routes for saving, editing, and deleting notifications.
 *
 * Usage:
 * This component is used for viewing, editing, and managing the broadcast status of a specific notification.
 * It allows users to update notification details, validate date fields, and manage the broadcast status through confirmation modals.
 *
 * Example Usage:
 * <ViewNotificationPage />
 */

const ViewNotificationPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const broadcastToast = () => toast.success(messages.notification.restart);
  const stopToast = () => toast.success(messages.notification.stop);

  // Start and pause modal variables
  const startModalTitle: string = "再配信注意";
  const startModalBody: string = "お知らせを再配信します。よろしいですか？";
  const startModalConfirm: string = "再配信する";

  const pauseModalTitle: string = "停止注意";
  const pauseModalBody: string = "お知らせを停止します。よろしいですか？";
  const pauseModalConfirm: string = "停止する";

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [notification, setNotification] = useState<NOTIFICATION | undefined>();
  const [pauseModalOpen, setPauseModalOpen] = useState<boolean>(false);
  const [startModalOpen, setStartModalOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(true);

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  const { viewOnly } = location.state || !isAdminLoggedIn;

  useEffect(() => {
    setLoading(true);
    getNotificationData(setNotification, setLoading, setError, id);
  }, [id]);

  const handleStopClick = () => {
    setPauseModalOpen(true);
  };

  const handleStopCloseModal = () => {
    setPauseModalOpen(false);
  };

  const handleStopConfirm = () => {
    setPauseModalOpen(false);
    stopToast();
    setStatus(false);
    if (notification) {
      notification.status = !notification?.status;
    } else {
      console.error(error_message.notification.is_null);
    }
  };

  const handleCloseStartModal = () => {
    setStartModalOpen(false);
  };

  const handleStartClick = () => {
    setStartModalOpen(true);
  };

  const handleStartConfirm = () => {
    setStartModalOpen(false);
    broadcastToast();
    setStatus(true);
    if (notification) {
      notification.status = !notification.status;
    } else {
      console.error(error_message.notification.is_null);
    }
  };

  const toggleEditMode = () => {
    setIsEditing((prev) => !prev);
  };

  const handleCancelButtonClick = () => {
    if (isEditing) {
      navigate(NOTIFICATION_PATHS.main);
    } else {
      toggleEditMode();
    }
  };

  const validateDates = () => {
    const startDate: Date = new Date(notification?.start_date);
    const endDate: Date = new Date(notification?.end_date);

    if (startDate > endDate) {
      errorToast(error_message.notification.start_data);
      return false;
    } else {
      return true;
    }
  };

  const handleUpdateButtonClick = () => {
    if (!isEditing) {
      let response;

      const validDates: boolean = validateDates();

      if (!validDates) {
        return;
      }

      putNotificationData(notification, setLoading, setError, notification?.id)
        .then((response) => {
          if (response.status == 200) {
            successToast(response.message);
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
      setTimeout(() => {
        navigate(NOTIFICATION_PATHS.main);
      }, 3000);
    } else {
      toggleEditMode();
    }
  };

  if (loading) {
    return (
      <div>
        <LoadingIcon></LoadingIcon>
      </div>
    );
  }

  if (notification?.status == false && !isAdminLoggedIn) {
    return (
      <div>
        <p className="not-found-text">
          {error_message.notification.not_found(id)}
        </p>
      </div>
    );
  }

  return (
    <>
      <TitleWithDivider titleText={"お知らせ登録・編集"} useDivider={true} />
      {notification ? (
        <div className="md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
          {!viewOnly && isAdminLoggedIn ? (
            <EditiableNotificationCard
              notificationData={notification}
              setNotificationData={setNotification}
              isEditing={isEditing}
              viewOnly={viewOnly}
            />
          ) : (
            <ViewNotificationCard notificationData={notification} />
          )}

          {!viewOnly && isAdminLoggedIn && (
            <div className="flex justify-between items-center gap-2.5 mt-8">
              <div
                className="inline-flex items-center justify-center gap-2.5 delete-button-frame"
                onClick={
                  notification?.status === false
                    ? handleStartClick
                    : handleStopClick
                }
              >
                {notification?.status === false ? "再配信" : "配信停止"}
              </div>
              <div className="flex justify-end">
                <div
                  className="button-cancel"
                  onClick={handleCancelButtonClick}
                >
                  キャンセル
                </div>
                <div
                  className="button-confirm"
                  onClick={handleUpdateButtonClick}
                >
                  {isEditing === false ? "更新する" : "確認"}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <p className="not-found-text">
          {error_message.notification.not_found(id)}
        </p>
      )}

      <ToastContainer />

      <PauseModal
        isOpen={pauseModalOpen}
        onRequestClose={handleStopCloseModal}
        onConfirm={handleStopConfirm}
        title={pauseModalTitle}
        body={pauseModalBody}
        confirmText={pauseModalConfirm}
        cancelText={""}
      />

      <StartModal
        isOpen={startModalOpen}
        onRequestClose={handleCloseStartModal}
        onConfirm={handleStartConfirm}
        title={startModalTitle}
        body={startModalBody}
        confirmText={startModalConfirm}
        cancelText={""}
      />
    </>
  );
};

export default ViewNotificationPage;
