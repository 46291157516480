export enum CONTRACT_STATUS {
  ACTIVE = "active",
  INACTIVE = "no_contract",
  EXPIRED = "expired",
}

export enum USER_TYPE {
  INTERNAL = 1,
  EXTERNAL = 2,
}

export enum USER_TYPE_STRING {
  INTERNAL = "internal",
  EXTERNAL = "external",
}
