const messages = {
  image: {
    upload_success: "イメージがアップロードされました。",
    icon_hint:
      "※きれいなアイコンにする,には、正方形の画像をアップロードしてください",
  },
  csv: {
    hint: " ※ ユーザーにアプリケーションを追加する際、YOBELY または KAKELYから選択できます。APP名は英語の大文字で入力してください。",
  },
  password: {
    success: "パスワードが正常に変更されました。",
    init_success: "パスワードが初期化されました。",
  },
  notification: {
    restart: "お知らせが再配信されました。",
    stop: "お知らせが停止されました。",
  },
  keycloak: {
    token_authed: "トークンが認証されました",
    wait_for_init:
      "Keycloakがまだ初期化されていません。しばらくお待ちください...",
  },
  user: {
    deleted: (count) => `${count}のユーザが削除されました`,
    uploaded: (createdCount, failedCount) =>
      `${createdCount}のユーザの情報がアップロードされました。${failedCount}のユーザの情報がアップロードされませんでした`,
    skipped_deletion: (userNum) =>
      `${userNum} 件のWEBユーザーアカウントが削除されました`,
  },
};

export default messages;
