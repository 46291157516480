import { SignalSlashIcon } from "@heroicons/react/16/solid";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { resetUserId } from "../../services/StateManagement/UserSlice";
import { resetAuthState } from "../../services/StateManagement/AuthSlice";
import { useDispatch } from "react-redux";
import { error_message } from "../../constants/Errors";

/**
 * NoConnectionModal - A modal component that informs the user there is no network connection.
 *
 * This component displays a modal with a warning icon and a message indicating that the user currently
 * has no network connection. It also provides a "ログアウト" button that allows the user to log out
 * and return to the realm select screen.
 *
 * @returns {JSX.Element} - A JSX element representing the no connection modal.
 *
 * Usage:
 * <NoConnectionModal />
 */

const NoConnectionModal: React.FC = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const handleLogout = () => {
    dispatch(resetUserId());
    dispatch(resetAuthState());

    localStorage.removeItem("userInfo");
    localStorage.setItem("logInState", "logging_out");
    keycloak.logout({ redirectUri: window.location.origin });
  };

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div className="bg-white border border-text-field-border rounded-lg w-110 p-6">
        <div className="flex flex-col items-center justify-center">
          <SignalSlashIcon className="w-16 h-16 mr-2 mb-4" />
          <p className="mb-4 text-md text-center">
            {error_message.generic.no_connection}
            <br />
            {error_message.generic.wait_retry}
          </p>
          <div>
            <button className="button-default" onClick={handleLogout}>
              ログアウト
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoConnectionModal;
