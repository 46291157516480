import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";

import TenantLicenseTable from "../../components/Tables/TenantLicenseTable";

import { TitleWithDivider } from "../../common/TitleWithDivider";
import { NewCard } from "../../components/Cards/Templates/newCard";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import { TENANT } from "../../types/tenant";
import {
  getCompanyContractData,
  getTenantData,
  putContractData,
  putTenantData,
} from "../../services/API/Requests";
import { toast, ToastContainer } from "react-toastify";
import LoadingIcon from "../../common/LoadingIcon";
import {
  handleKanaChange,
  handlePostCodeChange,
  handleRomanChange,
  phoneNumberChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages";
import { useSelector } from "react-redux";
import CreateTenantLicenseTable from "../../components/Tables/CreateTenantLicenseTable";
import { CONTRACT } from "../../types/contract";
import NotFound from "../../components/ErrorPages/PageNotFound";
import { CONTRACT_STATUS } from "../../constants/Enums";
import { TenantFieldErrorMessages } from "../../constants/Errors";

/**
 * ViewTenantPage - A component for viewing and updating tenant information including basic details, security settings, and contract status.
 *
 * This component allows users to view and update information related to a tenant, such as company name, postal code, address, phone number, and security settings. It also displays the current license status in a table format.
 *
 * State:
 * - `tenantData` (TENANT | undefined): Stores the tenant's data fetched from the backend API.
 * - `loading` (boolean): Indicates whether the component is in a loading state.
 * - `error` (string | null): Holds error messages encountered during API interactions.
 * - `errors` (object): Tracks validation errors for various fields such as `name_kana`, `name_english`, `admin_kana_name`, `postal_code`, `address`, and `phone_number`.
 *
 * Functions:
 * - `verifyFields`: Validates the input fields to ensure all required data is in a valid format.
 * - `updateTenantData`: Updates the `tenantData` state with the specified key-value pair.
 * - `onCancel`: Navigates the user back to the `/home` route without saving changes.
 * - `onSubmit`: Submits the updated tenant data to the backend API after validating the fields.
 * - `updateError`: Updates the `errors` state with the validation result for a specific field.
 *
 * API Interactions:
 * - `getTenantData`: Fetches tenant data from the backend API using the tenant ID stored in local storage.
 * - `putTenantData`: Sends the updated tenant data to the backend API for saving.
 *
 * Components:
 * - `TitleWithDivider`: A component for displaying a title with a divider.
 * - `NewCard`: A reusable card component used to display sections of tenant information.
 * - `TenantLicenseTable`: A table component for displaying the current contract status of the tenant's licenses.
 * - `ConfirmCancelButtons`: A component for displaying confirm and cancel buttons, with an optional form submission.
 * - `ToastContainer`: A component for displaying toast notifications.
 * - `LoadingIcon`: A component for displaying a loading spinner when data is being fetched.
 *
 * Form Validations:
 * - Validates fields like `name_kana`, `name_english`, `postal_code`, `phone_number`, and other necessary tenant information.
 * - Displays error messages when validation fails.
 *
 * Modals & Toasts:
 * - Uses `react-toastify` to show success and error messages for various operations.
 * - `errorToast`: Displays an error message using react-toastify.
 * - `successToast`: Displays a success message using react-toastify.
 *
 * Usage:
 * This component is used to manage tenant information by allowing users to view, update, and validate tenant details. It is intended to be used by administrators or users with appropriate permissions.
 *
 * Example Usage:
 * <ViewTenantPage />
 *
 * The component also interacts with local storage to retrieve the current tenant's ID and displays data accordingly.
 */

const ViewTenantPage: React.FC = () => {
  const { tenantCode } = useParams<{ tenantCode: string }>();
  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  if (tenantCode !== localStorage.getItem("realm") && !isAdminLoggedIn) {
    return <NotFound></NotFound>;
  }

  const navigate: NavigateFunction = useNavigate();

  const id: string | null = tenantCode || localStorage.getItem("realm");
  const [tenantData, setTenantData] = useState<TENANT | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>("");

  const [contractData, setContractData] = useState<CONTRACT[]>([]);

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [dateError, setDateError] = useState<boolean>(false);
  const [unitPriceError, setUnitPriceError] = useState<boolean>(false);

  const [errors, setErrors] = useState({
    name_kana: false,
    name_english: false,
    admin_kana_name: false,
    postal_code: false,
    address: false,
    phone_number: false,
  });

  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  useEffect(() => {
    if (id) {
      const fetchTenantData = async () => {
        setLoading(true);
        try {
          await getTenantData(setTenantData, setError, setLoading, id);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      };

      fetchTenantData();
    }
  }, [id]);

  useEffect(() => {
    const fetchLicenseData = async () => {
      setLoading(true);
      try {
        await getCompanyContractData(setContractData, setError, setLoading, id);
      } catch (error) {
        setError(error.message || "");
      } finally {
        setLoading(false);
      }
    };

    fetchLicenseData();
  }, []);

  const updateContractData = (
    app_id: number,
    key: keyof CONTRACT,
    value: any,
  ) => {
    setContractData((prevState) =>
      prevState.map((contract) =>
        contract.app_id === app_id ? { ...contract, [key]: value } : contract,
      ),
    );
  };

  const updateTenantData = (key: keyof TENANT, value: string | boolean) => {
    setTenantData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  const validateDates = () => {
    let hasError: boolean = false;

    const emptyContentError: string =
      "開始、または終了日日は空であってはいけません。";
    const illegalDatesError: string =
      "開始日と終了日は正しく設定してください。";

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        if (!contract.start_date || !contract.end_date) {
          hasError = true;
          errorToast(emptyContentError);
        }

        if (new Date(contract.start_date) > new Date(contract.end_date)) {
          hasError = true;
          errorToast(illegalDatesError);
        }
      }
    });

    setDateError(hasError);
    return hasError;
  };

  const validateUnitPrice = () => {
    const regex: RegExp = /^[0-9]*$/;
    let hasError: boolean = false;

    const emptyContentError: string = "単価は空であってはいけません。";
    const illegalCharactersError: string = "単価は数字のみ使用できます。";

    contractData.forEach((contract) => {
      if (contract.status === CONTRACT_STATUS.ACTIVE) {
        const unitPriceString: string = contract.unit_price.toString();
        if (unitPriceString === "0") {
          hasError = true;
          errorToast(emptyContentError);
        }
        if (!regex.test(unitPriceString)) {
          hasError = true;
          errorToast(illegalCharactersError);
        }
      }
    });

    setUnitPriceError(hasError);
    return hasError;
  };

  const onCancel = () => {
    navigate(!isAdminLoggedIn ? "/home" : "/company/manage");
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const validDates: boolean = validateDates();

    if (!verifyFields()) {
      errorToast("1つ以上のフィールドに無効な入力があります");
      return;
    }

    if (validDates) {
      return;
    }

    if (!validateUnitPrice) {
      return;
    }

    let response;
    putTenantData(tenantData, setLoading, setError)
      .then((response) => {
        if (response.status == 200) {
          if (isAdminLoggedIn) {
            putContractData(
              contractData,
              setLoading,
              setError,
              tenantCode,
            ).then((response) => {
              if (response.status == 200) {
                successToast("テナントと契約のリクエストが成功しました。");
              } else {
                const errorMessage: string = TranslateErrorMessage(response);
                console.error(errorMessage);
                setError(errorMessage);
                errorToast(errorMessage);
              }
            });
          } else {
            successToast(response.message);
          }
        } else {
          const errorMessage: string = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage: string = TranslateErrorMessage(response.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  return (
    <div>
      <TitleWithDivider titleText="企業情報設定" useDivider={true} />

      {loading ? (
        <LoadingIcon></LoadingIcon>
      ) : tenantData ? (
        <>
          <form onSubmit={onSubmit}>
            <NewCard
              header={"契約状態"}
              bodyLabel={""}
              isTable={true}
              isLoading={loading}
              bodyContents={
                <div className="mayo-card-body">
                  {isAdminLoggedIn ? (
                    <CreateTenantLicenseTable
                      contractData={contractData}
                      setContractData={setContractData}
                      updateContractData={updateContractData}
                    ></CreateTenantLicenseTable>
                  ) : (
                    <TenantLicenseTable id={id} />
                  )}
                </div>
              }
            />

            <NewCard
              header={"基本情報"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-6 label-light-blue-xl">
                    企業名 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData.name}
                    onChange={(e) =>
                      updateTenantData("name", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    企業名カナ <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData?.name_kana}
                    onChange={(e) => {
                      handleKanaChange(
                        "name_kana",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.name_kana && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.nameKanaError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    テナントID <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border-gray"
                    value={tenantData?.code}
                    onChange={(e) => {
                      handleRomanChange(
                        "name_english",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.name_english && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.nameFuriganaError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    郵便番号 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData?.postal_code}
                    onChange={(e) => {
                      handlePostCodeChange(
                        "postal_code",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.postal_code && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.postcodeError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    住所 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData.address}
                    onChange={(e) =>
                      updateTenantData("address", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">
                    電話番号 <span className="text-red-500">*</span>
                  </h2>
                  <input
                    type="text"
                    required
                    className="form-text-field-border"
                    value={tenantData?.phone_number}
                    onChange={(e) => {
                      phoneNumberChange(
                        "phone_number",
                        e.target.value,
                        updateTenantData,
                        updateError,
                      );
                    }}
                  />
                  {errors.phone_number && (
                    <p className="text-red-500">
                      {TenantFieldErrorMessages.telephoneNumberError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <h2 className="mb-6 label-light-blue-xl">企業URL</h2>
                  <input
                    type="text"
                    className="form-text-field-border"
                    value={tenantData.url}
                    onChange={(e) =>
                      updateTenantData("url", e.target.value.trim())
                    }
                  />
                  <div className="mt-5 divider"></div>
                </div>
              }
            />

            <NewCard
              header={"セキュリティ設定"}
              bodyLabel={""}
              isLoading={loading}
              bodyContents={
                <div>
                  <h2 className="mb-6 label-light-blue-xl">ログイン方法設定</h2>
                  <div className="flex ml-5 mb-2 text-xl text-black">
                    <label className="flex items-center mr-5">
                      <input
                        type="checkbox"
                        className="mr-5 w-5 h-5"
                        checked={tenantData.local_auth}
                        onChange={() =>
                          updateTenantData("local_auth", !tenantData.local_auth)
                        }
                      />
                      ローカル認証
                    </label>

                    {/* <label className="flex items-center ml-5">
                    <input
                      type="checkbox"
                      className="mr-5 w-5 h-5"
                      checked={tenantData.google_auth}
                      onChange={() =>
                        updateTenantData("google_auth", !tenantData.google_auth)
                      }
                    />
                    Google認証
                  </label>

                  <label className="flex items-center ml-5">
                    <input
                      type="checkbox"
                      className="mr-5 w-5 h-5"
                      checked={tenantData.microsoft_365_auth}
                      onChange={() =>
                        updateTenantData(
                          "microsoft_365_auth",
                          !tenantData.microsoft_365_auth,
                        )
                      }
                    />
                    Microsoft365認証
                  </label> */}
                  </div>

                  <div className="mt-5 divider"></div>

                  {/* <h2 className="mt-6 mb-6 label-light-blue-xl">
                  一斉発信通話内容録音可否
                </h2>
                <div className="ml-5 mb-2 text-xl text-black">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-5 w-5 h-5"
                      checked={tenantData.disable_call_recording}
                      onChange={() =>
                        updateTenantData(
                          "disable_call_recording",
                          !tenantData.disable_call_recording,
                        )
                      }
                    />
                    一斉発信内容の録音を行わない
                  </label>
                </div> 

                <div className="mt-5 divider"></div> */}

                  <h2 className="mt-6 mb-6 label-light-blue-xl">
                    電話帳参照制限
                  </h2>
                  <div className="ml-5 mb-2 text-xl text-black">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="mr-5 w-5 h-5"
                        checked={tenantData.require_passcode_for_phonebook}
                        onChange={() =>
                          updateTenantData(
                            "require_passcode_for_phonebook",
                            !tenantData.require_passcode_for_phonebook,
                          )
                        }
                      />
                      電話帳参照時にパスコードの入力を求める
                    </label>
                  </div>
                </div>
              }
            />
            <ConfirmCancelButtons
              confirmText={"更新する"}
              form={true}
              onCancel={onCancel}
            />
          </form>
        </>
      ) : (
        <p className="not-found-text">{`企業: ${id}のデータが見つかりませんでした`}</p>
      )}
      <ToastContainer />
    </div>
  );
};

export default ViewTenantPage;
