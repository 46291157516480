import { ShieldExclamationIcon } from "@heroicons/react/16/solid";
import { useKeycloak } from "@react-keycloak/web";
import React from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { error_message } from "../../constants/Errors";

/**
 * NoAuthModal - A modal component that prompts the user to log in when authentication is required.
 *
 * This component displays a modal with a warning icon and a message indicating that the user needs to log in.
 * It includes a "ログイン" button that triggers the Keycloak logout process and navigates the user to the
 * authentication page for re-login.
 *
 * @returns {JSX.Element} - A JSX element representing the no authentication modal.
 *
 * Usage:
 * <NoAuthModal />
 */

const NoAuthModal: React.FC = () => {
  const { keycloak } = useKeycloak();
  const navigate: NavigateFunction = useNavigate();

  const sessionExpired = keycloak.authenticated;

  const returnToAuthPage = () => {
    keycloak.logout();
    navigate("/");
  };
  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center backdrop-brightness-50">
      <div className="bg-white border border-text-field-border rounded-lg w-100 p-6">
        <div className="flex flex-col items-center justify-center">
          <ShieldExclamationIcon className="w-16 h-16 mr-2 mb-4"></ShieldExclamationIcon>
          {!sessionExpired ? (
            <p className="mb-4 text-center">
              {error_message.generic.session_expired}
            </p>
          ) : (
            <p className="mb-4 text-center">ログインしてください</p>
          )}
          <button className="button-default" onClick={returnToAuthPage}>
            {!sessionExpired ? "再ログイン" : "ログイン"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NoAuthModal;
