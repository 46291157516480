import React, { useEffect, useMemo, useState } from "react";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";

const tenant: string | null = localStorage.getItem("realm");
const adminTenant = import.meta.env.VITE_ADMIN_REALM;

// sidebar items used for non-admin users
export const DefaultSidebarItems = (companyId, availableApps) => {
  const [tenantApps, setTenantApps] = useState<String[]>([]);

  useMemo(() => {
    if (availableApps && Array.isArray(availableApps)) {
      const appNames = availableApps.map((app) => app.app_name);
      setTenantApps(appNames);
    }
  }, [availableApps]);

  return [
    {
      title: "企業管理",
      key: 1,
      icon: (
        <svg
          width="22"
          height="20"
          viewBox="0 0 22 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          title: "企業情報設定",
          path: `/company/${companyId}/info`,
        },
      ],
    },
    {
      key: 2,
      title: "ユーザ管理",
      icon: (
        <svg
          width="22"
          height="21"
          viewBox="0 0 22 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5M10.9999 19.5L13.025 19.095C13.2015 19.0597 13.2898 19.042 13.3721 19.0097C13.4452 18.9811 13.5147 18.9439 13.579 18.899C13.6516 18.8484 13.7152 18.7848 13.8426 18.6574L20.5 12C21.0524 11.4477 21.0523 10.5523 20.5 9.99997C19.9477 9.4477 19.0523 9.44771 18.5 9.99999L11.8425 16.6575C11.7152 16.7848 11.6516 16.8484 11.601 16.921C11.5561 16.9853 11.5189 17.0548 11.4902 17.1278C11.458 17.2102 11.4403 17.2984 11.405 17.475L10.9999 19.5ZM12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          title: "ユーザ一覧",
          path: "/users",
        },
        {
          title: "ユーザ新規追加",
          path: "/users/createUser",
        },
        {
          title: "ユーザCSVアップロード",
          path: "/users/csvUpload",
        },
      ],
    },
    tenantApps.includes("KAKELY") && {
      key: 3,
      title: "KAKELY管理",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.7143 17.4V20.28M20 21H6.28571C5.14286 21 4 20.28 4 18.84M4 18.84C4 17.4 5.14286 16.68 6.28571 16.68H20V3H6.28571C5.14286 3 4 4.08 4 5.16V18.84Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      children: [
        {
          title: "部署一覧",
          path: `${KAKELY_PATHS.allDepartmentSettings}`,
        },
        {
          title: "社外電話帳一覧",
          path: "/KAKELY",
        },
        {
          title: "電話帳CSVアップロード",
          path: "/KAKELY/csvUpload",
        },
      ],
    },
    tenantApps.includes("YOBELY") && {
      key: 4,
      title: "YOBELY管理",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.49 20.0119C19.668 20.9619 18.493 21.5 17.249 21.5C17.21 21.5 17.171 21.5 17.132 21.499C9.14396 21.282 2.71695 14.8549 2.50195 6.86789C2.46695 5.57089 3.01296 4.34187 3.99896 3.49387C4.93596 2.68687 6.15596 2.34996 7.34796 2.56296C7.53096 2.59596 7.68796 2.63785 7.82196 2.68185C8.34996 2.85685 8.73396 3.31404 8.82196 3.87204L9.492 8.12692C9.576 8.65992 9.36799 9.20006 8.95099 9.53806C8.86699 9.60606 8.77996 9.67093 8.68896 9.73093C8.45696 9.88893 8.36896 10.1579 8.47296 10.3969C9.47496 12.7019 11.295 14.5219 13.599 15.5239C13.839 15.6289 14.108 15.539 14.27 15.302C14.317 15.234 14.366 15.167 14.416 15.103C14.752 14.675 15.303 14.4689 15.847 14.5559L20.179 15.2771C20.751 15.3731 21.216 15.778 21.365 16.31C21.392 16.407 21.418 16.5171 21.44 16.6421C21.649 17.8431 21.303 19.0699 20.49 20.0119Z"
            stroke="white"
            strokeWidth="2"
          />
        </svg>
      ),
      children: [
        {
          title: "一斉発信グループ一覧・登録",
          path: "/YOBELY",
        },
      ],
    }, // {
    //   key: 6,
    //   title: "WORKJOY管理",
    //   icon: (
    //     <svg
    //       width="24"
    //       height="24"
    //       viewBox="0 0 24 24"
    //       fill="none"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //         d="M17.5556 16.4451L13.1111 12.0012V8.46831C14.6111 7.92393 15.6222 6.36856 15.2556 4.62433C14.9778 3.31338 13.8889 2.26906 12.5667 2.04686C10.4778 1.70246 8.66667 3.30227 8.66667 5.33535C8.66667 6.77962 9.6 8.0017 10.8889 8.46831V12.0012L6.44444 16.4451H3.11111C2.5 16.4451 2 16.9451 2 17.5561V20.889C2 21.5001 2.5 22 3.11111 22H6.44444C7.05556 22 7.55556 21.5001 7.55556 20.889V18.6115L12 13.9454L16.4444 18.6115V20.889C16.4444 21.5001 16.9444 22 17.5556 22H20.8889C21.5 22 22 21.5001 22 20.889V17.5561C22 16.9451 21.5 16.4451 20.8889 16.4451H17.5556Z"
    //         fill="white"
    //       />
    //     </svg>
    //   ),
    //   children: [
    //     {
    //       title: "メインページ",
    //       path: "/WORKJOY",
    //     },
    //     {
    //       title: "メンタルヘルスケア管理",
    //       path: "/WORKJOY/mentalheatlhCare/manage",
    //     },
    //     {
    //       title: "スタンプ管理",
    //       path: "/WORKJOY/mentalhealthCare/settings",
    //     },
    //     {
    //       title: "BIツール",
    //       path: "/WORKJOY/BITool/settings",
    //     },
    //   ],
    // },
    {
      key: 6,
      title: "HOME",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 21.0002V13.6002C9 13.0402 9 12.7601 9.10899 12.5462C9.20487 12.3581 9.35785 12.2051 9.54601 12.1092C9.75992 12.0002 10.0399 12.0002 10.6 12.0002H13.4C13.9601 12.0002 14.2401 12.0002 14.454 12.1092C14.6422 12.2051 14.7951 12.3581 14.891 12.5462C15 12.7601 15 13.0402 15 13.6002V21.0002M11.0177 2.76424L4.23539 8.03937C3.78202 8.39199 3.55534 8.5683 3.39203 8.7891C3.24737 8.98469 3.1396 9.20503 3.07403 9.4393C3 9.70376 3 9.99094 3 10.5653V17.8002C3 18.9203 3 19.4804 3.21799 19.9082C3.40973 20.2845 3.71569 20.5905 4.09202 20.7822C4.51984 21.0002 5.07989 21.0002 6.2 21.0002H17.8C18.9201 21.0002 19.4802 21.0002 19.908 20.7822C20.2843 20.5905 20.5903 20.2845 20.782 19.9082C21 19.4804 21 18.9203 21 17.8002V10.5653C21 9.99094 21 9.70376 20.926 9.4393C20.8604 9.20503 20.7526 8.98469 20.608 8.7891C20.4447 8.5683 20.218 8.39199 19.7646 8.03937L12.9823 2.76424C12.631 2.49099 12.4553 2.35436 12.2613 2.30184C12.0902 2.2555 11.9098 2.2555 11.7387 2.30184C11.5447 2.35436 11.369 2.49099 11.0177 2.76424Z"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
      path: "/home",
      state: { fromHomePage: true },
    },
  ].filter(Boolean); // Filter out any `false` entries (when the app is not available)
};

// sidebar items used for admin users
export const AdminSidebarItems = (companyId) => [
  {
    title: "企業管理",
    key: 10,
    icon: (
      <svg
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.5 5H9.25M6.5 9H9.25M6.5 13H9.25M12.75 5H15.5M12.75 9H15.5M12.75 13H15.5M19 19V4.2C19 3.0799 19 2.51984 18.782 2.09202C18.5903 1.71569 18.2843 1.40973 17.908 1.21799C17.4802 1 16.9201 1 15.8 1H6.2C5.07989 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M21 19H1"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        title: "企業一覧",
        path: `/company/manage`,
      },
      {
        title: "企業情報設定",
        path: `/company/${companyId}/manage`,
      },
      ...(tenant === adminTenant
        ? [
            {
              title: "企業追加",
              path: `/company/create`,
            },
          ]
        : []),
    ],
  },
  {
    key: 11,
    title: "ユーザ管理",
    icon: (
      <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 13H7C5.13623 13 4.20435 13 3.46927 13.3045C2.48915 13.7105 1.71046 14.4892 1.30448 15.4693C1 16.2044 1 17.1362 1 19M14.5 1.29076C15.9659 1.88415 17 3.32131 17 5M10.9999 19.5L13.025 19.095C13.2015 19.0597 13.2898 19.042 13.3721 19.0097C13.4452 18.9811 13.5147 18.9439 13.579 18.899C13.6516 18.8484 13.7152 18.7848 13.8426 18.6574L20.5 12C21.0524 11.4477 21.0523 10.5523 20.5 9.99997C19.9477 9.4477 19.0523 9.44771 18.5 9.99999L11.8425 16.6575C11.7152 16.7848 11.6516 16.8484 11.601 16.921C11.5561 16.9853 11.5189 17.0548 11.4902 17.1278C11.458 17.2102 11.4403 17.2984 11.405 17.475L10.9999 19.5ZM12.5 5C12.5 7.20914 10.7091 9 8.5 9C6.29086 9 4.5 7.20914 4.5 5C4.5 2.79086 6.29086 1 8.5 1C10.7091 1 12.5 2.79086 12.5 5Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        title: "ユーザ一覧",
        path: "/users",
      },
      {
        title: "ユーザ新規追加",
        path: "/users/createUser",
      },
    ],
  },
  {
    key: 12,
    title: "お知らせ管理",
    icon: (
      <svg
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.35419 20C8.05933 20.6224 8.98557 21 10 21C11.0145 21 11.9407 20.6224 12.6458 20M16 7C16 5.4087 15.3679 3.88258 14.2427 2.75736C13.1174 1.63214 11.5913 1 10 1C8.40872 1 6.8826 1.63214 5.75738 2.75736C4.63216 3.88258 4.00002 5.4087 4.00002 7C4.00002 10.0902 3.22049 12.206 2.34968 13.6054C1.61515 14.7859 1.24788 15.3761 1.26134 15.5408C1.27626 15.7231 1.31488 15.7926 1.46179 15.9016C1.59448 16 2.19261 16 3.38887 16H16.6112C17.8074 16 18.4056 16 18.5382 15.9016C18.6852 15.7926 18.7238 15.7231 18.7387 15.5408C18.7522 15.3761 18.3849 14.7859 17.6504 13.6054C16.7795 12.206 16 10.0902 16 7Z"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        title: "お知らせ一覧",
        path: "/notifications",
        state: { fromHomePage: false },
      },
      {
        title: " お知らせ追加",
        path: "/notifications/create",
      },
    ],
  },
];

export const myUserWithPasswordItems = (userId) => [
  {
    key: 8,
    title: "個人情報",
    icon: (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.25 15C4.00184 13.1419 6.38026 12 9 12C11.6197 12 13.9982 13.1419 15.75 15M12.375 5.625C12.375 7.48896 10.864 9 9 9C7.13604 9 5.625 7.48896 5.625 5.625C5.625 3.76104 7.13604 2.25 9 2.25C10.864 2.25 12.375 3.76104 12.375 5.625Z"
          stroke="white"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    children: [
      {
        title: "マイページ",
        path: `/users/myPage`,
      },
      {
        title: "パスワード変更",
        path: `/user/information/passwordReset`,
      },
    ],
  },
];
