import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { NewCard } from "../Cards/Templates/newCard.tsx";

import DepartmentModal from "../Modals/DepartmentModal.tsx";
import GroupModal from "../Modals/GroupModal.tsx";

import BlankUser from "../../dummy/BlankUserData.tsx";
import { ConfirmCancelButtons } from "../Buttons/ConfirmCancelButtons.tsx";
import {
  EyeIcon,
  EyeSlashIcon,
  PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { USER } from "../../types/user.ts";
import {
  deleteUserImage,
  getAppData,
  getRoleData,
  getUserData,
  postUserData,
  putUserData,
  resetUserPassword,
  uploadUserImage,
} from "../../services/API/Requests.tsx";

import LoadingIcon from "../../common/LoadingIcon.tsx";
import { USERTENANT } from "../../types/usertenant.tsx";
import { BlankUserTenantData } from "../../dummy/BlankUserTenantData.tsx";
import { ROLE } from "../../types/role.ts";
import { TENANTDEPARTMENT } from "../../types/tenantDepartment.ts";
import { BlankRoleData } from "../../dummy/BlankRoleData.tsx";
import { BROADCASTGROUP } from "../../types/broadcastGroup.ts";
import { BlankTenantDepartmentData } from "../../dummy/BlankTenantDepartmentData.tsx";
import { BlankBroadcastGroupData } from "../../dummy/BlankBroadcastGroupData.tsx";
import PasswordResetModal from "../Modals/PasswordResetModal.tsx";
import SkillTagModal from "../Modals/SkillTagModal.tsx";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  handleKanaChange,
  handleNumericalCheck,
  handleNumericalFieldChange,
  handleRomanChange,
  phoneNumberChange,
  TranslateErrorMessage,
  handlePasswordChange,
  handleHiraganaKanaChange,
} from "../../services/ErrorMessages.tsx";
import { APP } from "../../types/app.ts";
import { useSelector } from "react-redux";
import { GetPasswordPolicy } from "../../services/Utilities.tsx";
import {
  error_message,
  UserFieldErrorMessages,
} from "../../constants/Errors.tsx";
import messages from "../../constants/Messages.tsx";

interface UserInformationProps {
  id: string;
}

const UserProfileForm: React.FC<UserInformationProps> = ({ id }) => {
  const navigate = useNavigate();
  const [userData, setUser] = useState<USER | undefined>();
  const [userTenantData, setUserTenantData] = useState<
    USERTENANT | undefined
  >();

  const [tenantDepartmentData, setTenantDepartmentData] = useState<
    TENANTDEPARTMENT | undefined
  >();
  const [roles, setRoles] = useState<ROLE[]>([]);
  const [userRole, setUserRole] = useState<ROLE | undefined>();
  const [currentUserRole, setCurrentUserRole] = useState<ROLE | undefined>();
  const [adminRoleSelected, setAdminRoleSelected] = useState<boolean>(false);

  const isAdminLoggedIn: boolean = useSelector((state) => state.auth.isAdmin);

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");
  const [roleError, setRoleError] = useState<string>("");
  const passwordPolicy: string = GetPasswordPolicy();
  const [userNotFound, setUserNotFound] = useState<boolean>(false);

  const [groupMembership, setGroupMembership] = useState<
    TENANTDEPARTMENT | undefined
  >();

  const [imageLoading, setImageLoading] = useState<boolean>(true);

  const [broadCastGroup, setBroadcastGroup] = useState<
    BROADCASTGROUP | undefined
  >();

  const [createUser, setCreateUser] = useState(
    id === "createUser" ? true : false,
  );

  const [myUserPage, setMyUserPage] = useState(id === "myPage" ? true : false);
  const availableApps = useSelector((state) => state.apps.availableApps);

  const fileInput: React.MutableRefObject<null> = useRef(null);

  const [files, setFiles] = useState<File[]>([]);
  const [previews, setPreviews] = useState<string[]>([]);
  const [imageChange, setImageChanged] = useState<boolean>(false);
  const [doImageDelete, setDoImageDelete] = useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [apps, setApps] = useState<APP[]>([]);
  const [userApps, setUserApps] = useState<APP[]>([]);
  const [selectedApps, setSelectedApps] = useState<never[]>([]);

  const [userSkillTags, setUserSkillTags] = useState<never[]>([]); // currently unused

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [departmentModal, setDepartmentModalOpen] = useState<boolean>(false);
  const [passwordChangeModal, setPasswordChangeModalOpen] =
    useState<boolean>(false);
  const [comGroupModal, setCommunicationGroupModalOpen] =
    useState<boolean>(false);
  const [skillTagModal, setSkillTagModalOpen] = useState<boolean>(false);

  const [departmentModalTitle, setDepartmentModalTitle] = useState<
    string | null
  >(null);

  // errors for various user/contact variables
  const [errors, setErrors] = useState({
    name: false,
    first_name: false,
    last_name: false,
    first_kana_name: false,
    last_kana_name: false,
    furigana_name: false,
    first_furigana_name: false,
    last_furigana_name: false,
    personal_tel: false,
    employee_number: false,
    login_id: false,
    login_id_exists: false,
    email_exists: false,
    position_name: false,
    kana_name_length: false,
    furigana_name_length: false,
    password: false,
  });

  // check if we have a matching role for the user's role
  // if we do, set it in display
  // TODO: handle this in the api
  useEffect(() => {
    if (roles && roles.length > 0 && userRole) {
      const matchingRole = roles.find((role) => role.id === userRole.id);

      if (matchingRole) {
        setCurrentUserRole(matchingRole);
      } else {
        setCurrentUserRole(BlankRoleData);
      }
    } else if (isAdminLoggedIn) {
      const generalRole = roles.find((role) => role.id === 1);
      setCurrentUserRole(generalRole);
    } else {
      setCurrentUserRole(BlankRoleData);
    }
  }, [roles, userRole, createUser]);

  // get our initial user page data
  useEffect(() => {
    const fetchUserData = async () => {
      await getUserData(
        setUser,
        setUserTenantData,
        setTenantDepartmentData,
        setUserRole,
        setUserApps,
        setUserSkillTags,
        setLoading,
        setError,
        id,
        myUserPage,
      ).then((response) => {
        if (response.status >= 300) {
          setUserNotFound(true);
        } else {
          if (userData === undefined) {
            // this can occur only when the user is an external type and accesing myPage.
            // realistically should not happen.
            setUserNotFound(true);
          } else {
            setUserNotFound(false);
          }
        }
      });
    };

    // get all the roles available
    const fetchRoleData = async () => {
      await getRoleData(setRoles, setError, setLoading);
    };

    // get all the apps available
    const fetchAppData = async () => {
      await getAppData(setApps, setError, setLoading);
    };

    // if we're not creating a user, we fetch the user data for the user id
    // else we set all the initial values to blank/null
    if (!createUser) {
      fetchUserData();
    } else {
      setUser(BlankUser);
      setTenantDepartmentData(BlankTenantDepartmentData);
      setBroadcastGroup(BlankBroadcastGroupData);
      setUserTenantData(BlankUserTenantData);
      setLoading(false);
      if (isAdminLoggedIn) {
        updateUserData("personal_tel", "00000000000");
      }
    }
    fetchRoleData();
    fetchAppData();
  }, [id, createUser]);

  // set the user group membership if it is changed
  useEffect(() => {
    setGroupMembership(userTenantData);
  }, [tenantDepartmentData, userTenantData, createUser]);

  // if the user uploads a profile picture, update the preview visible in the form
  useEffect(() => {
    if (userData?.profile_image_url) {
      setPreviews([userData.profile_image_url]);
    }
  }, [userData?.profile_image_url]);

  // update the password of the user object if the field is updated in the form
  useEffect(() => {
    if (userData?.login_password) {
      setPassword(userData.login_password);
    }
  }, [userData?.login_password, password]);

  // check if the user role is set to admin
  useEffect(() => {
    if (currentUserRole?.name?.includes("管理者")) {
      setAdminRoleSelected(true);
    } else {
      setAdminRoleSelected(false);
    }
  }, [currentUserRole]);

  // setter to set the password variable value
  const updatePassword = (value) => {
    setPassword(value);
    if (userData) {
      userData.login_password = value;
    }
  };

  // generic function to update user/contact data based on key and value
  const updateUserData = (key: keyof USER, value: string | boolean) => {
    setUser((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  // generic function to update userTenantData based on key and value
  const updateUserTenantData = (
    key: keyof USERTENANT,
    value: string | boolean,
  ) => {
    setUserTenantData((prevState) => {
      if (prevState) {
        return {
          ...prevState,
          [key]: value,
        };
      }
      return prevState;
    });
  };

  // generic function to update error data based on key and value
  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  // generic function to update user login id
  const handleUpdateLoginId = (value) => {
    if (createUser) {
      updateUserData("login_id", value);
      updateError("login_id", false);
    } else {
      updateError("login_id", true);
      return;
    }
  };

  // handler for uploading/changing the user profile image in the form
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = Array.from(event.target.files!);
    setFiles(selectedImage);

    const filePreviews = selectedImage.map((file) => URL.createObjectURL(file));
    setPreviews(filePreviews);

    const fileName = selectedImage.length > 0 ? selectedImage[0].name : "";

    if (fileName !== userData?.profile_image_url) {
      setImageChanged(true);
    }
  };

  // handler for deleting the user profile image in the form
  const handleFileDelete = () => {
    setPreviews([]);
    setDoImageDelete(true);
  };

  // handle changes to the dropdown bar
  const handleDropdownChange = (e) => {
    const selectedRoleId = e.target.value;
    const selectedRole = roles.find(
      (role) => role.id.toString() === selectedRoleId,
    );

    if (selectedRole?.id != null) {
      setCurrentUserRole(selectedRole);
    } else {
      console.info("default id is selected.");
    }
  };

  // handler passed to tenantDepartment modal
  // this is run when the user selects a department from the modal
  // set the userTenantData department id as the id of the group selected.
  // TODO: confirm if we are using this in the future
  const handleSelectDepartment = (group) => {
    updateUserTenantData("tenant_department_id", group.id);
    updateUserTenantData("department_name", group.name);
    userTenantData.tenant_department_id = group.id;
    handleDepartmentModalClose();
  };

  // handler passed to broadcast group modal.
  // when the user selects a broadcast group the object is passed back
  // broadcast group is then set as the passed back object
  // TODO: confirm if we are using this in the future
  const handleSelectCommunicationGroup = (group) => {
    setBroadcastGroup(group);
    handleCommunicationGroupModalClose();
  };

  // when a new user creation request is submitted
  const createNewUser = async (event) => {
    event.preventDefault();

    const fields: boolean = verifyFields(); // make sure there are no errors in any of the fields

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    saveUserData(event); // save the user data
  };

  // upload (POST) the user image data to the API
  const processUserImage = async (id) => {
    await uploadUserImage(id, files);
  };

  // upload (POST) the user data to the API
  const saveUserData = async (event) => {
    event.preventDefault();
    let response; // expose response so the catch can see it
    const fields = verifyFields(); // make sure there are no errors in any of the fields

    if (!currentUserRole || currentUserRole.id === "") {
      setRoleError(UserFieldErrorMessages.roleError);
      errorToast(UserFieldErrorMessages.roleError);
      return;
    }

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    if (
      !userTenantData?.tenant_department_id &&
      availableApps.includes("KAKELY") &&
      !isAdminLoggedIn
    ) {
      setDepartmentModalTitle("ユーザ追加前に部署情報を登録してください。");
      setDepartmentModalOpen(true);
      return;
    }
    await postUserData(
      userData,
      userTenantData,
      broadCastGroup,
      currentUserRole,
      selectedApps,
      setLoading,
      setError,
    )
      .then((response) => {
        if (response.status == 200) {
          if (files.length >= 1) {
            processUserImage(response.user_id);
          }
          successToast(response.message);
        } else {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch(() => {
        const errorMessage = TranslateErrorMessage(response.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
    setLoading(false);
  };

  // when a user update request is submitted
  const handlePut = async (event) => {
    event.preventDefault();
    let response; // expose response so the catch can see it
    const fields = verifyFields(); // make sure there are no errors in any of the fields

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    if (!currentUserRole || currentUserRole.id === "") {
      setRoleError(error_message.user.role);
      errorToast(roleError);
      return;
    }

    putUserData(
      userData,
      userTenantData,
      broadCastGroup,
      currentUserRole,
      selectedApps,
      setLoading,
      setError,
    )
      .then((response) => {
        if (response.status == 200) {
          if (imageChange) {
            processUserImage(userData.id);
          } else if (doImageDelete) {
            deleteUserImage(userData?.id, setLoading, setError);
          }
          successToast(response.message);
        } else {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        }
      })
      .catch((error) => {
        const errorMessage = TranslateErrorMessage(response.message);
        console.error(errorMessage);
        setError(errorMessage);
        errorToast(errorMessage);
      });
  };

  useEffect(() => {
    if (!Array.isArray(userApps) || !Array.isArray(apps)) {
      return;
    }

    const updatedSelectedApps = apps?.filter((app) => {
      const isSelected = userApps?.some((userApp) => {
        return userApp?.app_id === app.id;
      });

      return isSelected;
    });

    setSelectedApps(updatedSelectedApps);
  }, [userApps, apps]);

  // handler for when the app data checkboxes are clicked
  const handleCheckboxChange = useCallback(
    (selectedApp) => {
      setSelectedApps((prevSelectedApps) => {
        if (prevSelectedApps.some((app) => app.id === selectedApp.id)) {
          return prevSelectedApps.filter((app) => app.id !== selectedApp.id);
        } else {
          return [...prevSelectedApps, selectedApp];
        }
      });
    },
    [selectedApps],
  );

  // handle password reset submission
  // passed to password reset modal and executed on confirm from the modal
  const submitPasswordReset = async (password) => {
    await resetUserPassword(password, setLoading, setError, userData?.id)
      .then((response) => {
        if (response.status == 200) {
          successToast(response.message);
        } else {
          errorToast(response.message);
        }
      })
      .catch((error) => {
        setError(error);
        errorToast(error);
      });
  };

  // function to verify if any of the fields contain errors
  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  /* department modal */
  const handleDepartmentClick = () => {
    setDepartmentModalOpen(true);
  };

  const handleDepartmentModalClose = () => {
    setDepartmentModalOpen(false);
  };

  const handledepartmentModalConfirm = () => {
    setDepartmentModalOpen(false);
  };

  /* COMMUNICATION modal */
  const handleCommunicationGroupModalClick = () => {
    setCommunicationGroupModalOpen(true);
  };

  const handleCommunicationGroupModalClose = () => {
    setCommunicationGroupModalOpen(false);
  };

  /* PASSWORD modal */

  const handleOpenPasswordModal = () => {
    setPasswordChangeModalOpen(true);
  };

  const handleClosePasswordModal = () => {
    setPasswordChangeModalOpen(false);
  };

  const handleCloseSkilltagModal = () => {
    setSkillTagModalOpen(false);
  };

  const handleOpenSkilltagModal = () => {
    setSkillTagModalOpen(true);
  };

  return (
    <div>
      {loading ? (
        <LoadingIcon></LoadingIcon>
      ) : userData ? (
        <form onSubmit={createUser ? createNewUser : handlePut}>
          <NewCard
            header={"基本情報"}
            bodyLabel={""}
            isLoading={loading}
            bodyContents={
              <div>
                {!isAdminLoggedIn && (
                  <>
                    <div>
                      {previews.length >= 1 ? (
                        <ul className="flex">
                          {previews.map((preview, index) => (
                            <li
                              key={index}
                              className="flex mt-10 flex-col items-center"
                            >
                              <div className="relative">
                                {imageLoading && (
                                  <div className="absolute inset-0 flex items-center justify-center">
                                    <LoadingIcon />
                                  </div>
                                )}
                                <img
                                  src={preview}
                                  alt={`preview ${index}`}
                                  onLoad={handleImageLoaded}
                                  className={`w-50 h-50 rounded-full cursor-pointer hover:opacity-50 ${
                                    imageLoading ? "opacity-0" : "opacity-100"
                                  }`}
                                  onClick={() => fileInput.current.click()}
                                />
                              </div>

                              <div className="flex items-center gap-2 mt-10 cursor-pointer hover:opacity-50">
                                <svg
                                  width="20"
                                  height="22"
                                  viewBox="0 0 22 22"
                                  fill="none"
                                  className="text-mayo-link-text"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M14 5V4.2C14 3.0799 14 2.51984 13.782 2.09202C13.5903 1.71569 13.2843 1.40973 12.908 1.21799C12.4802 1 11.9201 1 10.8 1H9.2C8.07989 1 7.51984 1 7.09202 1.21799C6.71569 1.40973 6.40973 1.71569 6.21799 2.09202C6 2.51984 6 3.0799 6 4.2V5M8 10.5V15.5M12 10.5V15.5M1 5H19M17 5V16.2C17 17.8802 17 18.7202 16.673 19.362C16.3854 19.9265 15.9265 20.3854 15.362 20.673C14.7202 21 13.8802 21 12.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V5"
                                    stroke="#0695ff"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                <div
                                  className="text-mayo-link-text"
                                  onClick={() => handleFileDelete()}
                                >
                                  画像を削除
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <ul className="flex">
                          <li className="flex flex-col items-center mt-10 cursor-pointer">
                            <div onClick={() => fileInput.current.click()}>
                              <svg
                                className="w-50 h-50 hover:opacity-50"
                                viewBox="0 0 100 100"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  width="100"
                                  height="100"
                                  rx="50"
                                  fill="#D9D9D9"
                                />
                                <path
                                  d="M50 50.375C42.373 50.375 36.25 44.252 36.25 36.625C36.25 29.1055 42.373 22.875 50 22.875C57.5195 22.875 63.75 29.1055 63.75 36.625C63.75 44.252 57.5195 50.375 50 50.375ZM55.3711 55.5312C65.6836 55.5312 74.0625 63.9102 74.0625 74.2227C74.0625 76.2637 72.3438 77.875 70.3027 77.875H29.5898C27.5488 77.875 25.9375 76.2637 25.9375 74.2227C25.9375 63.9102 34.209 55.5312 44.5215 55.5312H55.3711Z"
                                  fill="#8E8E8E"
                                />
                              </svg>
                            </div>
                            <div className="flex items-center gap-2 mt-10 hover:opacity-50">
                              <svg
                                width="20"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <PlusCircleIcon className="text-mayo-link-text"></PlusCircleIcon>
                              </svg>

                              <div
                                className="text-mayo-link-text cursor-pointer hover:opacity-50"
                                onClick={() => fileInput.current.click()}
                              >
                                画像を追加
                              </div>
                            </div>
                          </li>
                        </ul>
                      )}
                      <p className="mt-5 ml-2 text-md text-red-400 text-left">
                        {messages.image.icon_hint}
                      </p>
                    </div>
                    <div className="flex mt-5">
                      <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".png,.jpg,.jpeg"
                        ref={fileInput}
                        style={{ display: "none" }}
                        className="flex items-center w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-xl file:font-semibold file:bg-black file:text-white hover:file:opacity-60"
                      />
                    </div>
                    <div className="mt-5 divider"></div>

                    <h3 className="mt-10 mb-5 label-light-blue-xl">
                      権限 <span className="text-red-500">*</span>
                    </h3>

                    <div className="mb-2 text-xl text-black">
                      <label className="flex items-center">
                        <select
                          className="dropdown-bar"
                          value={currentUserRole?.id || ""}
                          onChange={(e) => handleDropdownChange(e)}
                        >
                          <option value="">未選択</option>
                          {roles?.map((role) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                        </select>
                      </label>
                      {roleError && <p className="text-red-500">{roleError}</p>}
                    </div>

                    <div className="mt-5 divider"></div>
                    <h3 className="mt-10 mb-5 label-light-blue-xl">
                      利用アプリ
                    </h3>

                    <div className="mb-2 text-xl text-black flex flex-row items-center gap-5 flex-wrap md:flex-nowrap">
                      {apps.length > 1 ? (
                        apps?.map((app) => (
                          <div
                            key={app.id}
                            className="text-xl text-black flex flex-row items-center gap-2 w-full md:w-auto"
                          >
                            <input
                              type="checkbox"
                              className="mr-5 w-4 h-4"
                              checked={selectedApps?.some(
                                (selectedApp) => selectedApp?.id === app.id,
                              )}
                              onChange={() => handleCheckboxChange(app)}
                            />
                            <label>{app.name}</label>
                          </div>
                        ))
                      ) : (
                        <div>{error_message.user.no_apps}</div>
                      )}
                    </div>

                    <div className="mt-5 divider"></div>
                  </>
                )}

                <div className="mt-5">
                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    名前 <span className="text-red-500">*</span>{" "}
                  </h2>
                  <div className="flex">
                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 姓 </h2>

                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userData?.last_name}
                        required
                        onChange={(e) =>
                          handleNumericalCheck(
                            "last_name",
                            e.target.value,
                            updateUserData,
                            updateError,
                          )
                        }
                      />
                      {errors.last_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameError}
                        </p>
                      )}
                    </div>

                    <div>
                      <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userData?.first_name}
                        required
                        onChange={(e) =>
                          handleNumericalCheck(
                            "first_name",
                            e.target.value,
                            updateUserData,
                            updateError,
                          )
                        }
                      />
                      {errors.first_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameError}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 divider"></div>

                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    氏名かな <span className="text-red-500">*</span>{" "}
                  </h2>

                  <div className="mt-10">
                    <div className="flex flex-row">
                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 姓 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={userData?.last_kana_name}
                          required
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "last_kana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.last_kana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>

                      <div>
                        <h2 className="mb-5 label-light-blue-md"> 名 </h2>
                        <input
                          type="text"
                          className="form-text-field-border"
                          value={userData?.first_kana_name}
                          required
                          onChange={(e) => {
                            handleHiraganaKanaChange(
                              "first_kana_name",
                              e.target.value,
                              updateUserData,
                              updateError,
                            );
                          }}
                        />
                        {errors.first_kana_name && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.nameHiraganaError}
                          </p>
                        )}
                        {errors.kana_name_length && (
                          <p className="text-red-500">
                            {UserFieldErrorMessages.lengthError}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-5 divider"></div>

                  <h2 className="mb-5 label-light-blue-xl"> 氏名英字 </h2>

                  <div className="flex flex-row">
                    <div>
                      <h4 className="mb-5 label-light-blue-md"> 姓 </h4>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userData?.last_furigana_name}
                        onChange={(e) => {
                          handleRomanChange(
                            "last_furigana_name",
                            e.target.value,
                            updateUserData,
                            updateError,
                          );
                        }}
                      />
                      {errors.last_furigana_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameFuriganaError}
                        </p>
                      )}
                      {errors.furigana_name_length && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.lengthError}
                        </p>
                      )}
                    </div>

                    <div>
                      <h4 className="mb-5 label-light-blue-md"> 名 </h4>
                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userData?.first_furigana_name}
                        onChange={(e) => {
                          handleRomanChange(
                            "first_furigana_name",
                            e.target.value,
                            updateUserData,
                            updateError,
                          );
                        }}
                      />
                      {errors.first_furigana_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.nameFuriganaError}
                        </p>
                      )}
                      {errors.furigana_name_length && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.lengthError}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="mt-5 divider"></div>

                  {!isAdminLoggedIn && (
                    <>
                      <h2 className="mb-5 label-light-blue-xl"> 社員番号 </h2>

                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userData?.employee_number}
                        onChange={(e) =>
                          handleNumericalFieldChange(
                            "employee_number",
                            e.target.value,
                            updateUserData,
                            updateError,
                          )
                        }
                      />
                      {errors.employee_number && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.employeeNumberError}
                        </p>
                      )}
                      <div className="mt-5 divider"></div>

                      <h2 className="mb-5 label-light-blue-xl">
                        {" "}
                        メールアドレス{" "}
                        {adminRoleSelected && (
                          <span className="text-red-500">*</span>
                        )}
                      </h2>

                      <input
                        type="email"
                        className="form-text-field-border"
                        value={userData?.email}
                        required={adminRoleSelected}
                        onChange={(e) =>
                          updateUserData("email", e.target.value)
                        }
                      />
                      {errors.email_exists && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.emailExistsError}
                        </p>
                      )}
                      <div className="mt-5 divider"></div>

                      <h2 className="mb-5 label-light-blue-xl">
                        {" "}
                        電話番号 <span className="text-red-500">*</span>{" "}
                      </h2>

                      <input
                        type="tel"
                        className="form-text-field-border"
                        value={userData?.personal_tel}
                        required
                        onChange={(e) => {
                          phoneNumberChange(
                            "personal_tel",
                            e.target.value,
                            updateUserData,
                            updateError,
                          );
                        }}
                      />
                      {errors.personal_tel && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.telephoneNumberError}
                        </p>
                      )}
                      <div className="mt-5 divider"></div>
                    </>
                  )}

                  <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    ログインID <span className="text-red-500">*</span>{" "}
                  </h2>

                  <input
                    type="text"
                    className={
                      !createUser
                        ? "form-text-field-border-gray"
                        : "form-text-field-border"
                    }
                    required={createUser}
                    value={userData?.login_id || ""}
                    readOnly={!createUser}
                    onChange={(e) => handleUpdateLoginId(e.target.value)}
                  />
                  {errors.login_id && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.loginIdError}
                    </p>
                  )}
                  {errors.login_id_exists && (
                    <p className="text-red-500">
                      {UserFieldErrorMessages.loginIdExistsError}
                    </p>
                  )}
                  <div className="mt-5 divider"></div>

                  <div className="mt-5 mb-5">
                    <h2 className="mb-5 label-light-blue-xl">
                      ログインパスワード <span className="text-red-500">*</span>
                    </h2>
                    <div className="relative">
                      <div>
                        <input
                          type={showPassword ? "text" : "password"}
                          value={
                            createUser
                              ? password
                              : "filler-text-make-it-look-like-a-password"
                          }
                          required
                          className="form-text-field-border"
                          onChange={(e) =>
                            handlePasswordChange(
                              "password",
                              e.target.value,
                              updatePassword,
                              updateError,
                            )
                          }
                        />
                        {!createUser ? (
                          <button
                            type="button"
                            className="ml-5 button-default-white"
                            onClick={handleOpenPasswordModal}
                          >
                            パスワードリセット
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="password-eye-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? (
                              <EyeSlashIcon className="w-6 h-6" />
                            ) : (
                              <EyeIcon className="w-6 h-6" />
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                    <div className="pt-10 text-zinc-400 font-bold">{`パスワードポリシー: ${passwordPolicy}`}</div>
                    {errors.password && (
                      <p className="text-red-500">
                        {UserFieldErrorMessages.passwordError}
                      </p>
                    )}
                  </div>

                  {!isAdminLoggedIn && (
                    <>
                      <div className="mt-5 divider"></div>
                      <h2 className="mb-5 label-light-blue-xl"> 所属部署 </h2>

                      <div>
                        <div className="flex items-center gap-2.5">
                          <input
                            type="text"
                            className="form-field-text whitespace-nowrap w-1/4"
                            value={
                              groupMembership?.department_name ||
                              groupMembership?.name
                            }
                            readOnly
                          />
                          <div
                            className="button-default-white"
                            onClick={handleDepartmentClick}
                          >
                            グループ選択
                          </div>
                        </div>

                        <div className="mt-5 divider"></div>
                      </div>

                      {/* <h2 className="mb-5 label-light-blue-xl">
                    {" "}
                    一斉発信グループ{" "}
                  </h2>

                  <div>
                    <div className="flex items-center gap-2.5">
                      <input
                        type="text"
                        className="form-field-text whitespace-nowrap w-1/4"
                        value={broadCastGroup?.broadcast_group_name}
                        readOnly
                      />
                      <div
                        className="button-default-white"
                        onClick={handleCommunicationGroupModalClick}
                      >
                        グループ選択
                      </div>
                    </div>
                    <div className="mt-5 divider"></div>
                  </div> */}

                      <h2 className="mb-5 label-light-blue-xl"> 役職 </h2>

                      <input
                        type="text"
                        className="form-text-field-border"
                        value={userTenantData?.position_name}
                        onChange={(e) =>
                          handleNumericalCheck(
                            "position_name",
                            e.target.value,
                            updateUserTenantData,
                            updateError,
                          )
                        }
                      />
                      {errors.position_name && (
                        <p className="text-red-500">
                          {UserFieldErrorMessages.positionError}
                        </p>
                      )}
                      <div className="mt-5 divider"></div>

                      <h2 className="mt-10 mb-5 label-light-blue-xl">
                        アカウント状態
                      </h2>

                      <div className="mb-2 text-xl text-black flex flex-row items-center gap-5">
                        <div className="text-xl text-black flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="mr-5 w-4 h-4"
                            checked={userData?.is_display}
                            onChange={() => updateUserData("is_display", true)}
                          />
                          <label>有効</label>
                        </div>
                        <div className="text-xl text-black flex flex-row items-center">
                          <input
                            type="checkbox"
                            className="mr-5 w-4 h-4"
                            checked={!userData?.is_display}
                            onChange={() => updateUserData("is_display", false)}
                          />
                          <label>無効</label>
                        </div>
                      </div>
                    </>
                  )}

                  {/* <h3 className="label-light-blue-xl mt-10">プロファイル</h3>
                  <div className="mt-5 divider"></div>

                  <div>
                    <div className="flex items-center mb-5">
                      <PlusCircleIcon className="h-5 w-5" />
                      <div
                        className="ml-2 link-text"
                        onClick={handleOpenSkilltagModal}
                      >
                        スキルタグ追加
                      </div>
                    </div>
                    <div className="flex">
                      {userSkillTags.map((tag, index) => (
                        <div key={index} className="button-skilltag">
                          {tag.name}
                        </div>
                      ))}
                    </div>
                  </div> */}
                </div>
              </div>
            }
          ></NewCard>
          <ConfirmCancelButtons
            confirmText={"更新する"}
            form={true}
            onCancel={() => {
              navigate("/users");
            }}
          ></ConfirmCancelButtons>
        </form>
      ) : (
        userNotFound && (
          <p className="not-found-text">
            {myUserPage
              ? error_message.user.not_found
              : error_message.user.not_found_id(id)}
          </p>
        )
      )}

      <PasswordResetModal
        isOpen={passwordChangeModal}
        onRequestClose={handleClosePasswordModal}
        onPasswordResetSuccess={submitPasswordReset}
      ></PasswordResetModal>
      <DepartmentModal
        isOpen={departmentModal}
        title={departmentModalTitle}
        onRequestClose={handleDepartmentModalClose}
        onSelectGroup={handleSelectDepartment}
      />
      <GroupModal
        isOpen={comGroupModal}
        onRequestClose={handleCommunicationGroupModalClose}
        onSelectGroup={handleSelectCommunicationGroup}
      />
      {/* currently unused */}
      <SkillTagModal
        isOpen={skillTagModal}
        onRequestClose={handleCloseSkilltagModal}
        onSelectGroup={function (group: any): void {
          throw new Error("Function not implemented.");
        }}
      ></SkillTagModal>
      <ToastContainer />
    </div>
  );
};

export default UserProfileForm;
