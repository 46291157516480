import React, { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { postTenantDepartmentData } from "../../services/API/Requests";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TitleWithDivider } from "../../common/TitleWithDivider";
import { BlankTenantDepartmentData } from "../../dummy/BlankTenantDepartmentData";
import { ConfirmCancelButtons } from "../../components/Buttons/ConfirmCancelButtons";
import { TENANTDEPARTMENT } from "../../types/tenantDepartment";
import {
  handleKanaChange,
  TranslateErrorMessage,
} from "../../services/ErrorMessages";
import { error_message, UserFieldErrorMessages } from "../../constants/Errors";
import { KAKELY_PATHS } from "../../constants/NavigationPaths";

/**
 * CreateDepartmentGroupPage - A page component for creating new tenant departments or master departments.
 *
 * This component provides a form for creating new tenant departments, with validation checks
 * and feedback using toast notifications. It includes form fields for the department code, name,
 * and name in Kana, and utilizes APIs to save the department data to the backend.
 *
 * Props:
 * - `parentId` (string): The ID of the parent department if any, passed from the URL parameters.
 * - `navigate` (function): The hook function used to navigate between routes.
 *
 * State:
 * - `department` (TENANTDEPARTMENT | null): Holds the current department data being created or edited.
 * - `isLoading` (boolean): Indicates whether the data is currently being saved or fetched.
 * - `error` (string): Stores any error messages encountered during the API call.
 * - `errors` (object): Tracks validation errors for specific fields like `name` and `name_kana`.
 *
 * Functions:
 * - `handleInputChange`: Updates the department state with changes in form input fields.
 * - `verifyFields`: Verifies that all required fields are filled and valid before submitting the form.
 * - `updateError`: Updates the validation errors state for specific fields.
 * - `handleSave`: Handles the form submission, validates the inputs, sends the department data to the backend, and navigates back to the settings page upon success.
 * - `onCancel`: Navigates back to the settings page without saving any data.
 *
 * Toast Notifications:
 * - `errorToast`: Displays an error toast with a specific message.
 * - `successToast`: Displays a success toast with a specific message.
 *
 * API Interactions:
 * - `postTenantDepartmentData`: Sends the department data to the backend for creation.
 *
 * Error Handling:
 * - `TranslateErrorMessage`: Converts backend error messages into user-friendly messages.
 * - `UserFieldErrorMessages`: Contains predefined error messages for user input validation.
 * - `handleKanaChange`: Handles Kana input changes and validation.
 *
 * Components:
 * - `TitleWithDivider`: A component for displaying the title with an optional divider line.
 * - `ConfirmCancelButtons`: A component that displays "Confirm" and "Cancel" buttons with their respective actions.
 * - `ToastContainer`: A component from react-toastify used to display toast notifications.
 *
 * Usage:
 * This component is used to create new tenant departments or master departments with fields for department name and Kana.
 * It validates inputs and provides feedback through toast notifications.
 *
 * Example Usage:
 * <CreateDepartmentGroupPage />
 */

const CreateDepartmentGroupPage: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const { parentId } = useParams<{ parentId: string }>();

  const isNotMaster: string | null =
    parentId && parentId !== "Master" ? parentId : null;

  const [department, setDepartment] = useState<TENANTDEPARTMENT | null>(
    BlankTenantDepartmentData,
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const errorToast = (response) => toast.error(`${response}`);
  const successToast = (response) => toast.success(`${response}`);

  const [errors, setErrors] = useState({
    name: false,
    name_kana: false,
  });

  useEffect(() => {
    setDepartment(BlankTenantDepartmentData);
  }, []);

  const handleInputChange = (key: string, value: string) => {
    setDepartment((prevState) =>
      prevState ? { ...prevState, [key]: value } : prevState,
    );
  };

  const verifyFields = () => {
    return !Object.values(errors).some((error) => error === true);
  };

  const updateError = (field, value) => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: value,
    }));
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const fields = verifyFields();

    if (!fields) {
      errorToast(error_message.generic.bad_field);
      return;
    }

    if (department) {
      if (isNotMaster) {
        department.parent = parentId;
      }
      let response;
      await postTenantDepartmentData(
        setDepartment,
        department,
        setIsLoading,
        setError,
      )
        .then((response) => {
          if (response.status == 200) {
            successToast(response.message);
          } else {
            const errorMessage = TranslateErrorMessage(response.message);
            console.error(errorMessage);
            setError(errorMessage);
            errorToast(errorMessage);
          }
        })
        .catch((error) => {
          const errorMessage = TranslateErrorMessage(response.message);
          console.error(errorMessage);
          setError(errorMessage);
          errorToast(errorMessage);
        });
    }

    setTimeout(() => {
      navigate(KAKELY_PATHS.allDepartmentSettings);
    }, 1000);
  };

  const onCancel = () => {
    navigate(KAKELY_PATHS.allDepartmentSettings);
  };

  return (
    <div className="container mx-auto p-4">
      <TitleWithDivider
        titleText={isNotMaster ? "新部署追加" : "新マスター部署追加"}
        useDivider={true}
      />
      <form
        className="mayo-card-body border border-text-field-border p-4"
        onSubmit={handleSave}
      >
        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署コード <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department?.display_order}
            required
            onChange={(e) => handleInputChange("display_order", e.target.value)}
          />
        </div>

        <div className="divider"></div>

        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署名 <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department?.name}
            required
            onChange={(e) => handleInputChange("name", e.target.value)}
          />
        </div>

        <div className="divider"></div>

        <div className="mb-4">
          <label className="mb-5 flex justify-normal text-2xl font-bold items-center">
            部署名(カナ)
          </label>
          <input
            type="text"
            className="form-text-field-border"
            value={department?.name_kana}
            onChange={(e) =>
              handleKanaChange(
                "name_kana",
                e.target.value,
                handleInputChange,
                updateError,
              )
            }
          />
          {errors.name_kana && (
            <p className="text-red-500">
              {UserFieldErrorMessages.nameKanaError}
            </p>
          )}
        </div>

        <ConfirmCancelButtons
          confirmText={"作成"}
          onCancel={onCancel}
          form={true}
        ></ConfirmCancelButtons>
      </form>
      <ToastContainer />
    </div>
  );
};

export default CreateDepartmentGroupPage;
