import React, { useEffect, useRef, useState } from "react";
import {
  Link,
  NavigateFunction,
  useLocation,
  useNavigate,
} from "react-router-dom";
import PaginationControls from "../../services/PaginationControls";
import LoadingIcon from "../../common/LoadingIcon";
import { HeaderItems } from "../../common/HeaderItems";
import { getNotificationsData } from "../../services/API/Requests";
import { useSelector } from "react-redux";
import { error_message } from "../../constants/Errors";

/**
 * NotificationTable - A component for displaying and managing notifications in a table format.
 *
 * This component fetches notification data from the API and displays it in a paginated table.
 * Users can navigate through different pages using pagination controls and view more details
 * or create new notifications. The component also handles navigation and state management
 * for various user interactions.
 *
 * @returns {JSX.Element} - A JSX element representing the notification table.
 *
 * State:
 * - currentPage {number} - The current page number in the pagination.
 * - totalPages {number} - The total number of pages available for pagination.
 * - perPage {number} - The number of items to display per page.
 * - notificationData {Array} - The array of notifications to be displayed.
 * - loading {boolean} - A flag indicating whether the data is being loaded.
 * - error {string} - An error message to display if data fetching fails.
 *
 * Hooks:
 * - useEffect - Fetches notification data when the component mounts or page changes.
 * - useLocation - Retrieves the current location information from the router.
 * - useNavigate - Provides navigation methods to programmatically navigate between routes.
 *
 * Usage:
 * <NotificationTable />
 */

const NotificationTable = () => {
  const navigate: NavigateFunction = useNavigate();

  const isNormalUser = useSelector((state) => !state.auth.isAdmin);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [notificationData, setNotificationData] = useState<undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>("");

  const [itemsPerPage, setItemsPerPage] = useState<number>(5);
  const lastNotificationIndex: number = currentPage * itemsPerPage;
  const firstNotificationIndex: number = lastNotificationIndex - itemsPerPage;
  const currentNotifications: any = notificationData?.slice(
    firstNotificationIndex,
    lastNotificationIndex,
  );

  const totalPages: number = Math.ceil(notificationData?.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  useEffect(() => {
    getNotificationsData(
      setNotificationData,
      setLoading,
      setError,
      isNormalUser,
    );
  }, []);

  const fetchNotifcations = (page = 1) => {
    getNotificationsData(
      setNotificationData,
      setLoading,
      setError,
      isNormalUser,
    );
  };

  // Handle page change for pagination
  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const headerItems = [
    {
      icon: {
        width: 22,
        height: 22,
        viewBox: "0 0 22 22",
        path: "M7.35419 20C8.05933 20.6224 8.98557 21 10 21C11.0145 21 11.9407 20.6224 12.6458 20M16 7C16 5.4087 15.3679 3.88258 14.2427 2.75736C13.1174 1.63214 11.5913 1 10 1C8.40872 1 6.8826 1.63214 5.75738 2.75736C4.63216 3.88258 4.00002 5.4087 4.00002 7C4.00002 10.0902 3.22049 12.206 2.34968 13.6054C1.61515 14.7859 1.24788 15.3761 1.26134 15.5408C1.27626 15.7231 1.31488 15.7926 1.46179 15.9016C1.59448 16 2.19261 16 3.38887 16H16.6112C17.8074 16 18.4056 16 18.5382 15.9016C18.6852 15.7926 18.7238 15.7231 18.7387 15.5408C18.7522 15.3761 18.3849 14.7859 17.6504 13.6054C16.7795 12.206 16 10.0902 16 7Z",
      },
      text: " お知らせ追加",
      onClick: () => navigate("/notifications/create"),
    },
  ];

  if (loading) {
    return (
      <div>
        <LoadingIcon></LoadingIcon>
      </div>
    );
  }

  return (
    <>
      {!isNormalUser && (
        <HeaderItems items={headerItems} topPadding={5}></HeaderItems>
      )}
      <div className="mt-5 bg-white min-w-full border border-text-field-border rounded-2xl overflow-auto">
        <table className="bg-white min-w-full rounded-2xl table-fixed">
          <thead className="border-b border-text-field-border">
            <tr className="w-full flex mayo-card-header">
              <th className="p-2.5 xl:p-5 w-1/4 text-left">
                <h5 className="text-font-label-blue text-xl">配信日時</h5>
              </th>
              <th className="p-2.5 xl:p-5 w-1/2 text-left">
                <h5 className="text-font-label-blue text-xl">タイトル</h5>
              </th>
              <th className="p-2.5 xl:p-5 w-1/4"></th>
            </tr>
          </thead>
          <tbody className="mayo-card-body divide-y divide-text-field-border rounded-2xl">
            {currentNotifications && currentNotifications.length > 0 ? (
              currentNotifications.map((n, key) => (
                <tr className="flex" key={key}>
                  <td className="p-2.5 xl:p-5 w-1/4 text-black text-xl">
                    {n.start_date?.split("T")[0]}
                  </td>
                  <td className="p-2.5 xl:p-5 w-1/2 text-xl">
                    <Link
                      to={`/notifications/info/${n.id}`}
                      state={{ viewOnly: isNormalUser }}
                      className="text-xl flex items-center text-left hover:opacity-50 cursor-pointer"
                    >
                      {n.title}
                    </Link>
                  </td>
                  <td className="p-2.5 xl:p-5 w-1/4"></td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="p-2.5 xl:p-5 text-center text-xl">
                  {error_message.notification.no_data}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <PaginationControls
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
        perPage={itemsPerPage}
        displayedItem={"お知らせ"}
        totalItems={notificationData?.length}
        setPerPage={setItemsPerPage}
      ></PaginationControls>
    </>
  );
};

export default NotificationTable;
